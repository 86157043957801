import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import TierTransformer from '@/assets/js/dexie/transformers/TierTransformer.js'
import ContractTransformer from '@/assets/js/dexie/transformers/ContractTransformer.js'
import ContractTiersTransformer from '@/assets/js/dexie/transformers/ContractTiersTransformer.js'
import ContractConfigTransformer from '@/assets/js/dexie/transformers/ContractConfigTransformer.js'
import ContractConditionsTransformer from '@/assets/js/dexie/transformers/ContractConditionsTransformer.js'
import ContractTypeMonteTransformer from '@/assets/js/dexie/transformers/ContractTypeMonteTransformer.js'
import ContractConfigTypeMonteTransformer from '@/assets/js/dexie/transformers/ContractConfigTypeMonteTransformer.js'
import ContractConfigConditionsTransformer from '@/assets/js/dexie/transformers/ContractConfigConditionsTransformer.js'
import NumberTemplateTransformer from '@/assets/js/dexie/transformers/NumberTemplateTransformer.js'
import ContractAvenantTransformer from '@/assets/js/dexie/transformers/ContractAvenantTransformer.js'
import ContractAvenantArticlesTransformer from '@/assets/js/dexie/transformers/ContractAvenantArticlesTransformer.js'
import ContractAvenantTypeTransformer from '@/assets/js/dexie/transformers/ContractAvenantTypeTransformer.js'
import AccountingMixin from '@/mixins/Accounting.js'
import HorseMixin from '@/mixins/Horse.js'
import InvoiceMixin from '@/mixins/Invoice.js'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
import ContractAvenantCleaner from '@/assets/js/cache/cleaners/ContractAvenantCleaner'
import SeasonCleaner from '@/assets/js/cache/cleaners/SeasonCleaner'
import SeasonTransformer from '@/assets/js/dexie/transformers/SeasonTransformer.js'

import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'

var ContractMixin = {
    data() {
        return {
        }
    },
    mixins: [AccountingMixin, HorseMixin, InvoiceMixin],
    methods: {
        loadContractsConfig: async function(horse_id) {
            return this.$storage.db.t('contract_config')
            .then(table => {
                return table.where({contractconfig_horse: horse_id})
            })
            .then(col => {
                return col.transform(new ContractConfigTransformer())
            })
        },
        loadContractsConfigBySeason: async function(season_id) {
            return this.$storage.db.t('contract_config')
            .then(table => {
                return table.where({contractconfig_season: parseInt(season_id)})
            })
            .then(col => {
                return col.transform(new ContractConfigTransformer('light'))
            })
		},
		async getSeasonStallions(season_id) {
			const contract_configs = await this.loadContractsConfigBySeason(season_id)

			const stallions = new Set()
			contract_configs.forEach(contract_config => {
				stallions.add(contract_config.contractconfig_horse)
			})

			return this.$storage.db.t('horse')
			.then(table => {
				return table
					.where('horse_id')
					.anyOf(stallions)
					.transform(new HorseTransformer())
			})
		},
        getContractsConfig: async function(contractconfig_id) {
            return this.$storage.db.t('contract_config')
            .then(table => {
                return table.get(parseInt(contractconfig_id))
            })
            .then(item => {
                return ContractConfigTransformer.process(item)
            })
        },
        getContractsConfigByStallionAndSeason: async function(horse_id, season_id, transformerName = '') {
            return this.$storage.db.t('contract_config')
            .then(table => {
                return table.where({
                    contractconfig_horse: parseInt(horse_id),
                    contractconfig_season: parseInt(season_id)
                })
            })
            .then(col => {
                return col.transform(new ContractConfigTransformer(transformerName))
            })
		},
		getStallionSeasonContractConfigIds: async function(horse_id, season_id) {
			return this.$storage.db.t('contract_config')
			.then(table => {
				return table.where({
					contractconfig_horse: parseInt(horse_id),
					contractconfig_season: parseInt(season_id)
				})
				.primaryKeys()
			})
		},
        getConditionsMonte: async function() {
            return this.$storage.db.t('contract_conditions')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new ContractConditionsTransformer())
            })
        },
		getConditionsMonteCaInvoice: async function() {
			const url = Constants.CONTRACT_CONDITIONS_CA_INVOICE_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("ContractMixin::getConditionsMonteCaInvoice", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("ContractMixin::getConditionsMonteCaInvoice => ERROR", e)
					return null
				})
		},
		setConditionsMonteCaInvoice: async function(condition_id, state) {
			const url = this.constructRoute(Constants.CONTRACT_CONDITIONS_SET_CA_INVOICE_URL, { condition_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_api("ContractMixin::getConditionsMonteCaInvoice", url, { state }, false)
				.then(res => res.retour)
				.catch(e => {
					console.error("ContractMixin::setConditionsMonteCaInvoice => ERROR", e)
					return null
				})
		},
        getContractConfigTypeMonte: async function(contractconfig_id, light = false) {
            contractconfig_id = parseInt(contractconfig_id)
            contractconfig_id = this.$sync.replaceWithReplicated('contract_config', contractconfig_id)

            return this.$storage.db.t('contract_config_type_monte')
            .then(table => {
                return table.where({
                    contractconfigtypemonte_contractconfig: contractconfig_id,
                    contractconfigtypemonte_valide: 1
                })
            })
            .then(col => {
                if(light)
                    return col.transform(new ContractConfigTypeMonteTransformer('withTypeMonte'))
                return col.transform(new ContractConfigTypeMonteTransformer())
            })
        },
        getContractConfigTypeMonteFromId: async function(contractconfigtypemonte_id) {
            return this.$storage.db.t('contract_config_type_monte')
            .then(table => {
                return table.get(parseInt(contractconfigtypemonte_id))
            })
            .then(type_monte => {
                return ContractConfigTypeMonteTransformer.process(type_monte, 'withTypeMonte')
            })
        },
        getAllAvenantFromContract: async function(contract_id) {
            return this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.where({
                    avenant_contract: parseInt(contract_id)
                })
            })
            .then(contract => {
                return ContractAvenantTransformer.process(contract, 'ultraLight')
            })
        },
        getContractConfigCondition: async function(contractconfig_id, contractconfigconditions_conditions, contractconfigtypemonte_id) {
            return this.$storage.db.t('contract_config_conditions')
            .then(table => {
                return table.where({
                    contractconfigconditions_contractconfig: parseInt(contractconfig_id),
                    contractconfigconditions_conditions: parseInt(contractconfigconditions_conditions),
                    contractconfigconditions_typemonte: parseInt(contractconfigtypemonte_id),
                    contractconfigconditions_valide: 1,
                })
            })
            .then(col => {
                return col.transform(new ContractConfigConditionsTransformer())
            })
        },
        getContractConfigConditionByTypeMonte: async function(contractconfig_id, contractconfigtypemonte_id) {
            return this.$storage.db.t('contract_config_conditions')
            .then(table => {
                return table.where({
                    contractconfigconditions_contractconfig: parseInt(contractconfig_id),
                    contractconfigconditions_typemonte: parseInt(contractconfigtypemonte_id),
                })
            })
            .then(col => {
                return col.transform(new ContractConfigConditionsTransformer())
            })
        },
        getTypeMonte: async function() {
            return this.$storage.db.t('contract_type_monte')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new ContractTypeMonteTransformer())
            })
        },

        addContractConfig: async function(horse_id, season_id) {
            const config = await this.$storage.db.t('contract_config')
            .then(table => {
                return table.add({
                    contractconfig_id: Common.getNegativeId(),
                    contractconfig_horse: horse_id,
                    contractconfig_season: season_id,
                    contractconfig_valide: 1
                })
            })

            SeasonCleaner.inst().onMutation([season_id], ['contracts_config_stallion'])

            return config
		},
		addContractConfigModel: async function(contractconfig_id, params) {
			const url = this.constructRoute(Constants.CONTRACT_CONFIG_MODEL, { contractconfig_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ModelMixin::isLinkModelTiers", url, params, false)
			.catch(error => {
				console.error("ModelMixin::isLinkModelTiers => ERROR", error)
				return null
			})

			if(result) return result.retour
			return null
		},
        updateContractConfig: async function(contractconfig_id, fields) {
            let params = {
                contractconfig_fromnumber: fields.contractconfig_fromnumber,
                contractconfig_season: fields.contractconfig_season.id,
                contractconfig_paillette: fields.contractconfig_paillette,
                contractconfig_paillette_recommended: fields.contractconfig_paillette_recommended,
                contractconfig_default_cmep: (Object.keys(fields.cmep).length > 0) ? fields.cmep.tiers_id : null,
                contractconfig_configuration: (fields.contractconfig_configuration && fields.contractconfig_configuration.checkdepositconfiguration_id) ? fields.contractconfig_configuration.checkdepositconfiguration_id : null
            }

            await this.$storage.db.t('contract_config')
            .then(table => {
                return table.update(parseInt(contractconfig_id), params)
            })

            SeasonCleaner.inst().onMutation([fields.contractconfig_season.id], ['contracts_config_stallion'])

            params = {
                numbertemplate_prefix: fields.contractconfig_prefix.numbertemplate_prefix,
                numbertemplate_pattern: fields.contractconfig_prefix.numbertemplate_pattern,
            }

            let number_template = await this.getContractPrefix(contractconfig_id)
            if(number_template != undefined)
            {
                number_template.numbertemplate_id = this.$sync.replaceWithReplicated('number_template', number_template.numbertemplate_id)

                //Update
                return await this.$storage.db.t('number_template')
                .then(table => {
                    return table.where({
                        numbertemplate_id: number_template.numbertemplate_id,
                    }).modify(params)
                })
            }
            else
            {
                //Ajout
                return this.$storage.db.t('number_template')
                .then(table => {
                    return table.add({
                        numbertemplate_id: Common.getNegativeId(),
                        numbertemplate_prefix: fields.contractconfig_prefix.numbertemplate_prefix,
                        numbertemplate_pattern: fields.contractconfig_prefix.numbertemplate_pattern,
                        numbertemplate_valide: 1,
                        numberable_id: parseInt(contractconfig_id),
                        numberable_type: "App\\Model\\ContractConfig",
                        numberable_field: "contractPrefix",
                    })
                })
            }
        },
		updateContractConfigConditionAvailableCa: async function(contractconfigtypemonte_id, available) {
			await this.$storage.db.t('contract_config_type_monte')
            .then(table => {
                return table.update(parseInt(contractconfigtypemonte_id), { contractconfigtypemonte_available_ca: available })
            })
		},
		updateContractConfigGlobalConditionAvailableCa: async function(contractconfigglobaltypemonte_id, available) {
			const url = Constants.CONTRACT_CONFIG_GLOBAL_SWITCH_CA + "?licence_key="+Constants.USER_LICENCE_KEY

			const params = {
				contractconfigglobaltypemonte_id: contractconfigglobaltypemonte_id,
				available: available
			}

			const result = await this.$request.request_post_api("ContractMixin::sendMailContracts", url, params, false)
			.catch(error => {
				console.error("ContractMixin::updateContractConfigGlobalConditionAvailableCa => ERROR", error)
				return null
			})
            return result ? result.retour : null
		},
        archiveTypeMonte: async function (contractconfigtypemonte_id, archive) {
            return await this.$storage.db.t('contract_config_type_monte')
            .then(table => {
                return table.update(parseInt(contractconfigtypemonte_id), {
                    contractconfigtypemonte_archive: archive
                })
            })
        },
        deleteTypeMonte: async function(contractconfigtypemonte_id) {
            await this.$storage.db.transaction('rw',
                [
                    'contract_config_conditions',
                    'contract_config_conditions_articles',
                    'contract_config_type_monte'
                ],
                async () => {
                    let contract_conditions_ids = []
                    //Récupération des conditions pour pouvoir supprimer les articles
                    await this.$storage.db.t('contract_config_conditions')
                    .then(table => {
                        return table.where({contractconfigconditions_typemonte: parseInt(contractconfigtypemonte_id)})
                    })
                    .then(col => {
                        return col.each(function(item){
                            contract_conditions_ids.push(item.contractconfigconditions_id)
                        })
                    })

                    contract_conditions_ids = this.$sync.replaceWithReplicated('contract_config_conditions', contract_conditions_ids)

                    //Suppression des articles
                    await this.$storage.db.t('contract_config_conditions_articles')
                    .then(table => {
                        return table.where('contractconfigconditionsarticles_contractconfigconditions').anyOf(contract_conditions_ids)
                    })
                    .then(col => {
                        return col.invalid()
                    })

                    //Suppression des conditions
                    await this.$storage.db.t('contract_config_conditions')
                    .then(table => {
                        return table.where('contractconfigconditions_id').anyOf(contract_conditions_ids)
                    })
                    .then(col => {
                        return col.invalid()
                    })

                    // Supprimer le type monte
                    await this.$storage.db.t('contract_config_type_monte')
                    .then(table => {
                        return table.update(
							parseInt(contractconfigtypemonte_id),
							{ contractconfigtypemonte_valide: 0 }
						)
                    })
                }
            )
		},
		printContract: async function(contract_id, return_stream = false) {
            let contract = contract_id
            if(contract_id < 0){
                await this.$sync.force()
                contract = this.$sync.replaceWithReplicated('contract', contract_id)
            }
			const url = this.constructRoute(Constants.CONTRACT_PRINT_URL, { contract_id: contract }) + "?licence_key="+Constants.USER_LICENCE_KEY + "&return_stream=" + return_stream
			const result = await this.$request.request_get_api("ContractMixin::printContract", url)
			.catch(error => {
				console.error("ContractMixin::printContract => ERROR", error)
				return null
			})
            if(return_stream){
                return result
            }
            Common.base64ToPdf(result, "contract_" + contract + ".pdf")
            return true
		},
		printContractLot: async function(contract_ids) {
            for (let i = 0; i < contract_ids.length; i++) {
                if(contract_ids[i] < 0) {
                    await this.$sync.force()
                    contract_ids[i] = this.$sync.replaceWithReplicated('contract', contract_ids[i])
                }
            }

			const url = this.constructRoute(Constants.CONTRACT_PRINT_LOT_URL, { contract_ids: contract_ids.join(",") }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("ContractMixin::printContractLot", url)
			.catch(error => {
				console.error("ContractMixin::printContractLot => ERROR", error)
				return null
			})
            Common.base64ToPdf(result, "contract_" + contract_ids.join(",") + ".pdf")
            return true
		},
		sendMailContracts: async function(contract_ids, model_id = null, commentaire = null, expeditor = null, courtierMail = null, mails_coproprios = null, insemination_center_mail= null, send_mail_sender = false, model_courtier = null, model_cmep = null, model_coproprios = null) {
			const url = Constants.CONTRACT_SEND_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			const params = {
				contract_ids: contract_ids,
				model_id: model_id,
                expeditor: expeditor,
                commentaire: commentaire,
                courtier_mail: courtierMail,
                mails_coproprios: mails_coproprios,
                insemination_center_mail: insemination_center_mail,
                send_mail_sender: send_mail_sender,
                model_courtier: model_courtier,
                model_cmep: model_cmep,
                model_coproprios: model_coproprios
			}

			const result = await this.$request.request_post_api("ContractMixin::sendMailContracts", url, params, false)
			.catch(error => {
				console.error("ContractMixin::sendMailContracts => ERROR", error)
				return null
			})

			return result ? result.retour : null
		},
		deleteContracts: async function(contract_ids) {

            let contracts_to_delete = []


            await Common.asyncForEach(contract_ids, async (contract_id, i) => {
                const contract_avenant = await this.$storage.db.t('contract_avenant')
                .then(table => {
                    return table.filter(avenant => avenant.avenant_contract === parseInt(contract_id))
                    .first()
                })

                if(contract_avenant) {
                    let avenants_articles = await this.getAvenantArticles(contract_avenant.avenant_id)
                    let invoiceable_article = false
                    for (let i = 0; i < avenants_articles.length; i++) {
                        for (let j = 0; j < avenants_articles[i].length; j++) {
                            if(avenants_articles[i][j].avenantarticles_invoiceable == true)
                            {
                                invoiceable_article = true
                            }
                        }
                    }

                    if(invoiceable_article == false)
                    {
                        contracts_to_delete.push(contract_id)
                    }
                    else {
                        throw new Error(`Cannot delete contract ${contract_id}`)
                    }
                }
            })

			return this.$storage.db.transaction(
				'rw',
				['contract', 'contract_avenant', 'contract_tiers', 'saillie'],
				async () => {
					await Common.asyncForEach(contracts_to_delete, async (contract_id, i) => {

                        const contract_avenant = await this.$storage.db.t('contract_avenant')
                        .then(table => {
                            return table.where({
                                avenant_contract: parseInt(contract_id),
							})
							.invalid()
                        })

                        const saillie = await this.$storage.db.t('saillie')
                        .then(table => {
                            return table.where({
                                saillie_contract: parseInt(contract_id),
                            })
                            .first()
                        })

                        if(saillie)
                        {
                            await this.$storage.db.t('saillie')
                             .then(table => {
                                 return table.update(parseInt(saillie.saillie_id), { saillie_contract: null })
                             })
                        }

						await this.$storage.db.t('contract')
						.then(table => {
							return table.update(parseInt(contract_id), { contract_valide: 0 })
						})
                        await this.$storage.db.t('contract_tiers')
                        .then(table => {
                            return table.where({
                                contracttiers_contract: parseInt(contract_id),
							})
							.invalid()
                        })
					})
				}
			)
		},
        addContractConditions: async function(contractconfig_id, conditions, articles, countries) {
            contractconfig_id = parseInt(contractconfig_id)
            let typemonte_id = parseInt(conditions.contractconfigtypemonte_typemonte.id)

            contractconfig_id = this.$sync.replaceWithReplicated('contract_config', contractconfig_id)
            typemonte_id = this.$sync.replaceWithReplicated('contract_type_monte', typemonte_id)

            let id = await this.$storage.db.t('contract_config_type_monte')
            .then(table => {
                return table.add({
                    contractconfigtypemonte_id: Common.getNegativeId(),
                    contractconfigtypemonte_typemonte: typemonte_id,
                    contractconfigtypemonte_contractconfig: contractconfig_id,
                    contractconfigtypemonte_label: conditions.contractconfigtypemonte_label,
                    contractconfigtypemonte_comment: conditions.contractconfigtypemonte_comment,
                    contractconfigtypemonte_paillette: conditions.contractconfigtypemonte_paillette,
                    contractconfigtypemonte_valide: 1
                })
            })

            id = this.$sync.replaceWithReplicated('contract_config_type_monte', id)

            await this.$storage.db.t('contract_config_conditions')
            .then(table_cond => {
                return Common.asyncForEach(articles, async (article, index) => {
                    if(article != undefined) {
                        const contract_config_cond_id = await table_cond.add({
                            contractconfigconditions_id: Common.getNegativeId(),
                            contractconfigconditions_contractconfig: contractconfig_id,
                            contractconfigconditions_typemonte: id,
                            contractconfigconditions_conditions: parseInt(index),
                            contractconfigconditions_valide: 1
                        })

                        await Common.asyncForEach(article, async (elem, i) => {

                            await this.$storage.db.t('contract_config_conditions_articles')
                            .then(table_article => {
                                return table_article.add({
                                    contractconfigconditionsarticles_id: Common.getNegativeId(),
                                    contractconfigconditionsarticles_articles: parseInt(elem.id),
                                    contractconfigconditionsarticles_contractconfigconditions: contract_config_cond_id,
                                    contractconfigconditionsarticles_ht: (elem.ht.toString().replace(',', '.').replace(/\s/g, '') * 100),
                                    contractconfigconditionsarticles_htunit: (elem.htunit.toString().replace(',', '.').replace(/\s/g, '') * 100),
                                    contractconfigconditionsarticles_qte: elem.qte,
                                    contractconfigconditionsarticles_ttc: (elem.ttc.toString().replace(',', '.').replace(/\s/g, '') * 100),
                                    contractconfigconditionsarticles_valide: 1
                                })

                            })
                        })
                    }
                })
            })

            await this.$storage.db.t('contract_config_type_monte_pays')
            .then(table_pays => {
                return Common.asyncForEach(countries, async (country) => {
                    await table_pays.add({
                        typemontepays_id: Common.getNegativeId(),
                        typemontepays_typemonte: parseInt(id),
                        typemontepays_pays: parseInt(country),
                        typemontepays_valide: 1
                    })
                })
            })

            return id

        },
        editContractConditions: async function(contractconfigtypemonte_id, contractconfig_id, conditions, articles, countries) {
            contractconfig_id = parseInt(contractconfig_id)

            let id = this.$sync.replaceWithReplicated('contract_config_type_monte', contractconfigtypemonte_id)
            let contract_conditions_ids = []


            await this.$storage.db.t('contract_config_type_monte')
            .then(table => {
                return table.update(parseInt(contractconfigtypemonte_id), {
                    contractconfigtypemonte_label: conditions.contractconfigtypemonte_label,
                    contractconfigtypemonte_comment: conditions.contractconfigtypemonte_comment,
                    contractconfigtypemonte_paillette: conditions.contractconfigtypemonte_paillette,
                    contractconfigtypemonte_archive: conditions.contractconfigtypemonte_archive,
                    contractconfigtypemonte_typemonte: conditions.contractconfigtypemonte_typemonte.id
                })
            })

            //Récupération des conditions pour pouvoir supprimer les articles
            await this.$storage.db.t('contract_config_conditions')
            .then(table => {
                return table.where({contractconfigconditions_typemonte: parseInt(contractconfigtypemonte_id)})
            })
            .then(col => {
                return col.each(function(item){
                    contract_conditions_ids.push(item.contractconfigconditions_id)
                })
            })

            contract_conditions_ids = this.$sync.replaceWithReplicated('contract_config_conditions', contract_conditions_ids)

            //Suppression des articles
            await this.$storage.db.t('contract_config_conditions_articles')
            .then(table => {
                return table.where('contractconfigconditionsarticles_contractconfigconditions').anyOf(contract_conditions_ids)
            })
            .then(col => {
                return col.invalid()
            })

            //Suppression des conditions
            await this.$storage.db.t('contract_config_conditions')
            .then(table => {
                return table.where('contractconfigconditions_id').anyOf(contract_conditions_ids)
            })
            .then(col => {
                return col.invalid()
            })

            await this.$storage.db.t('contract_config_conditions')
            .then(table_cond => {
                return Common.asyncForEach(articles, async (article, index) => {
                    if(article != undefined) {
                        const contract_config_cond_id = await table_cond.add({
                            contractconfigconditions_id: Common.getNegativeId(),
                            contractconfigconditions_contractconfig: contractconfig_id,
                            contractconfigconditions_typemonte: id,
                            contractconfigconditions_conditions: parseInt(index),
                            contractconfigconditions_valide: 1
                        })

                        await Common.asyncForEach(article, async (elem, i) => {

                            await this.$storage.db.t('contract_config_conditions_articles')
                            .then(table_article => {
                                return table_article.add({
                                    contractconfigconditionsarticles_id: Common.getNegativeId(),
                                    contractconfigconditionsarticles_articles: parseInt(elem.id),
                                    contractconfigconditionsarticles_contractconfigconditions: contract_config_cond_id,
                                    contractconfigconditionsarticles_ht: (elem.ht.toString().replace(',', '.').replace(/\s/g, '') * 100),
                                    contractconfigconditionsarticles_htunit: (elem.htunit.toString().replace(',', '.').replace(/\s/g, '') * 100),
                                    contractconfigconditionsarticles_qte: elem.qte,
                                    contractconfigconditionsarticles_ttc: (elem.ttc.toString().replace(',', '.').replace(/\s/g, '') * 100),
                                    contractconfigconditionsarticles_valide: 1
                                })

                            })
                        })
                    }
                })
            })

            //Suppression des pays
            await this.$storage.db.t('contract_config_type_monte_pays')
            .then(table => {
                return table.where({typemontepays_typemonte: parseInt(contractconfigtypemonte_id)})
            })
            .then(col => {
                return col.invalid()
            })

            await this.$storage.db.t('contract_config_type_monte_pays')
            .then(table_pays => {
                return Common.asyncForEach(countries, async (country) => {
                    await table_pays.add({
                        typemontepays_id: Common.getNegativeId(),
                        typemontepays_typemonte: parseInt(contractconfigtypemonte_id),
                        typemontepays_pays: parseInt(country),
                        typemontepays_valide: 1
                    })
                })
            })

            return id

        },
        addGlobalContractConditions: async function(season_id, contractconfigglobal_id, conditions, articles, countries) {
            let params = {
                season_id: season_id,
                contractconfigglobal_id: contractconfigglobal_id,
                conditions: conditions,
                articles: articles,
                countries_available: countries
            }
            const url = this.constructRoute(Constants.CONDITIONS_GLOBAL_URL, params)+"?licence_key="+Constants.USER_LICENCE_KEY
			const data = {
			}

			return this.$request.request_post_api("ContractMixin::addGlobalContractConditions", url, params, false)
				.then(res => res.retour)
				.catch(e => {
					console.error("ContractMixin::addGlobalContractConditions => ERROR", e)
					return null
				})
            
        },

        getContractPrefix: async function(contractconfig_id){

            return this.$storage.db.t('number_template')
            .then(table => {
                return table.where({
                    numberable_id: parseInt(contractconfig_id),
                    numberable_field: 'contractPrefix'
                }).first()
            })
            .then(item => {
                return NumberTemplateTransformer.process(item)
            })
        },


        loadContracts: async function(horse_id) {
            return this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.where({avenant_horse: parseInt(horse_id)})
            })
            .then(col => {
                return col.transform(new ContractAvenantTransformer())
            })
        },

        loadContractTiers: async function(tiers_id) {
            return this.$storage.db.t('contract_tiers')
            .then(table => {
                return table.where({contracttiers_tiers: parseInt(tiers_id)})
            })
            .then(col => {
                return col.toArray()
            })
			.then(arr => {
				return arr.map(ct => ct.contracttiers_contract)
			})
        },

        loadContractTiersOnline: async function(tiers_id) {
			const url = this.constructRoute(Constants.TIERS_CONTRACTS_URL, { tiers_id }) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("ContractMixin::loadContractTiersOnline", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("ContractMixin::loadContractTiersOnline => ERROR", e)
					return null
				})
		},

        loadContractsCourtier: async function(tiers_id) {
            return this.$storage.db.t('contract')
            .then(table => {
                return table.where({contract_courtier: parseInt(tiers_id)})
            })
            .then(col => {
                return col.primaryKeys()
            })
        },

        loadContractsLieuMonte: async function(tiers_id) {
			const table = await this.$storage.db.t('contract_avenant')

			// Récupérer tous les derniers avenants
			const avenants = await table.where('avenant_cmep').anyOf(parseInt(tiers_id))

			// Vérifier si c'est le dernier avenant du contrat
			let contracts_set = new Set()
			await avenants.each(async (avenant) => {
				const has_next_avenants = await table.where('avenant_contract').equals(avenant.avenant_contract)
					.and((contract_avenant) => {
						contract_avenant.avenant_date > avenant.avenant_date
					})
					.count()

				if (!has_next_avenants) {
					contracts_set.add(avenant.avenant_contract)
				}
			})

			// Transformation du Set en Array
			const contract_ids = []
			contracts_set.forEach(contract_id => {
				contract_ids.push(contract_id)
			})
			return contract_ids
        },

        loadContractsFromTiers: async function(tiers_id) {
            return this.$storage.db.t('contract_tiers')
            .then(table => {
                return table.where({contracttiers_tiers: parseInt(tiers_id)})
            })
            .then(col => {
                return col.transform(new ContractTiersTransformer())
            })
        },

        getAllContracts: async function() {
            return this.$storage.db.t('contract')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new ContractTransformer('withLastAvenant'))
			})
        },

        getAvenantOfMare: async function(horse_id) {
            let avenants = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.where({
                    avenant_horse: parseInt(horse_id)
                })
            })
            .then(col => {
                return col.transform(new ContractAvenantTransformer('ultraLight'))
            })
            .then(async (res) => {

                let tab = []
                let temp = _orderBy(res, ['avenant_num'], ['desc'])

                let temp_bis = _groupBy(temp, 'avenant_contract')

                // await Object.keys(temp_bis).forEach(async (avenant_id) => {
                await Common.asyncForEach(Object.keys(temp_bis), async (avenant_id) => {
                    const last_avenant = await this.$storage.db.t('contract_avenant')
                    .then(table => {
                        return table.where({
                            avenant_contract: temp_bis[avenant_id][0].avenant_contract
                        })
                    })
                    .then(col => {
                        return col.transform(new ContractAvenantTransformer('ultraLight'))
                    })
                    .then(col => {
                        return _orderBy(col, ['avenant_num'], ['desc'])[0]
                    })

                    if(last_avenant.avenant_num == temp_bis[avenant_id][0]["avenant_num"])
                    {
                        tab.push(temp_bis[avenant_id][0])
                    }
                })
                return tab
            })

            return avenants
        },

        getAvenant: async function() {
            let avenants = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new ContractAvenantTransformer('ultraLight'))
            })
            .then(async (res) => {
                let tab = []
                let temp = _orderBy(res, ['avenant_num'], ['desc'])

                let temp_bis = _groupBy(temp, 'avenant_contract')

                // await Object.keys(temp_bis).forEach(async (avenant_id) => {
                await Common.asyncForEach(Object.keys(temp_bis), async (avenant_id) => {
                    const last_avenant = await this.$storage.db.t('contract_avenant')
                    .then(table => {
                        return table.where({
                            avenant_contract: temp_bis[avenant_id][0].avenant_contract
                        })
                    })
                    .then(col => {
                        return col.transform(new ContractAvenantTransformer('ultraLight'))
                    })
                    .then(col => {
                        return _orderBy(col, ['avenant_num'], ['desc'])[0]
                    })

                    if(last_avenant.avenant_num == temp_bis[avenant_id][0]["avenant_num"])
                    {
                        tab.push(temp_bis[avenant_id][0])
                    }
                })
                return tab
            })

            return avenants
        },

        getAllAvenant: async function(contract_id) {
            let avenants = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.where({
                    avenant_contract: parseInt(contract_id)
                })
            })
            .then(col => {
                return col.transform(new ContractAvenantTransformer('allWithContract'))
            })

            return avenants
        },


        getAvenantOfStallion: async function(horse_id) {
            let tab = []

            let contract_config = await this.$storage.db.t('contract_config')
            .then(table => {
                return table.where({
                    contractconfig_horse: parseInt(horse_id)
                }).primaryKeys()
            })

            await Common.asyncForEach(contract_config, async (contractconfig_id) => {
                let contracts = await this.$storage.db.t('contract')
                .then(table => {
                    return table.where({
                        contract_contractconfig: parseInt(contractconfig_id)
                    })
                })
                .then(col => {
                    return col.transform(new ContractTransformer('onlyAvenantId'))
                    // return col.transform(new ContractTransformer('all')) //Avant optimisation
                })

                for (let i = 0; i < contracts.length; i++) {
                    tab.push(contracts[i])
                }
            })

            return tab
        },

        getAllContractsFromStallion: async function() {
            return this.$storage.db.t('contract')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new ContractTransformer('withContractConfig'))
            })
        },

        getContract: async function(contract_id) {
            const id = this.$sync.replaceWithReplicated('contract', contract_id)

            return this.$storage.db.t('contract')
            .then(table => {
                return table.get(parseInt(id))
            })
            .then(item => {
                return ContractTransformer.process(item)
            })
        },

        getContractOnline: async function(contract_id) {
            const url = this.constructRoute(Constants.CONTRACT_GET_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::getContractOnline", url)
			.catch(error => {
				console.error("HorseMixin::getContractOnline => ERROR", error)
				return null
			})

			if(response) return response.retour
            return null
        },

        addContractFromHorse: async function(horse_id){
            let contract_id = await this.$storage.db.t('contract')
            .then(table => {
                return table.add({
                    contract_id: Common.getNegativeId(),
                    contract_num: null,
                    contract_note: null,
                    contract_contractconfig: null,
                    contract_valide: 1
                })
            })

            const type = await this.$storage.db.t('contract_avenant_type')
            .then(table => {
                return table.where({avenanttype_type: 'initial'}).first()
            })

            const status = await this.$storage.db.t('contract_avenant_status')
            .then(table => {
                return table.where({avenantstatus_type: 'brouillon'}).first()
            })

            //J'ajoute l'avenant
            const avenant_id = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.add({
                    avenant_id: Common.getNegativeId(),
                    avenant_num: 1,
                    avenant_contract: contract_id,
                    avenant_type: type.avenanttype_id,
                    avenant_free: 0,
                    avenant_comment: null,
                    avenant_typemonte: null,
                    avenant_justification: null,
                    avenant_status: status.avenantstatus_id,
                    avenant_horse: parseInt(horse_id),
                    avenant_valide: 1
                })
            })

            return contract_id
        },

        addContractFromStallion: async function(){
            let contract_id = await this.$storage.db.t('contract')
            .then(table => {
                return table.add({
                    contract_id: Common.getNegativeId(),
                    contract_num: null,
                    contract_note: null,
                    contract_contractconfig: null,
                    contract_valide: 1
                })
            })

            const type = await this.$storage.db.t('contract_avenant_type')
            .then(table => {
                return table.where({avenanttype_type: 'initial'}).first()
            })

            const status = await this.$storage.db.t('contract_avenant_status')
            .then(table => {
                return table.where({avenantstatus_type: 'brouillon'}).first()
            })

            //J'ajoute l'avenant
            const avenant_id = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.add({
                    avenant_id: Common.getNegativeId(),
                    avenant_num: 1,
                    avenant_contract: contract_id,
                    avenant_type: type.avenanttype_id,
                    avenant_free: 0,
                    avenant_typemonte: null,
                    avenant_comment: null,
                    avenant_justification: null,
                    avenant_status: status.avenantstatus_id,
                    avenant_horse: null,
                    avenant_valide: 1
                })
            })

            return contract_id
        },

        addContractFromTiers: async function(tiers_id){

            let contract_id = await this.$storage.db.t('contract')
            .then(table => {
                return table.add({
                    contract_id: Common.getNegativeId(),
                    contract_num: null,
                    contract_note: null,
                    contract_contractconfig: null,
                    contract_valide: 1
                })
            })

            const type = await this.$storage.db.t('contract_avenant_type')
            .then(table => {
                return table.where({avenanttype_type: 'initial'}).first()
            })

            const status = await this.$storage.db.t('contract_avenant_status')
            .then(table => {
                return table.where({avenantstatus_type: 'brouillon'}).first()
            })

            //J'ajoute l'avenant
            const avenant_id = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.add({
                    avenant_id: Common.getNegativeId(),
                    avenant_num: 1,
                    avenant_contract: contract_id,
                    avenant_type: type.avenanttype_id,
                    avenant_free: 0,
                    avenant_typemonte: null,
                    avenant_comment: null,
                    avenant_justification: null,
                    avenant_status: status.avenantstatus_id,
                    avenant_horse: null,
                    avenant_valide: 1
                })
            })

            const contract_tiers_id = await this.$storage.db.t('contract_tiers')
            .then(table => {
                return table.add({
                    contracttiers_id: Common.getNegativeId(),
                    contracttiers_contract: contract_id,
                    contracttiers_tiers: parseInt(tiers_id),
                    contracttiers_valide: 1
                })
            })

            return contract_id
        },
        addContract: async function(){

            let contract_id = await this.$storage.db.t('contract')
            .then(table => {
                return table.add({
                    contract_id: Common.getNegativeId(),
                    contract_num: null,
                    contract_note: null,
                    contract_contractconfig: null,
                    contract_created: new Date(),
                    contract_valide: 1
                })
            })

            const type = await this.$storage.db.t('contract_avenant_type')
            .then(table => {
                return table.where({avenanttype_type: 'initial'}).first()
            })

            const status = await this.$storage.db.t('contract_avenant_status')
            .then(table => {
                return table.where({avenantstatus_type: 'brouillon'}).first()
            })

            //J'ajoute l'avenant
            const avenant_id = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.add({
                    avenant_id: Common.getNegativeId(),
                    avenant_num: 1,
                    avenant_contract: contract_id,
                    avenant_type: type.avenanttype_id,
                    avenant_free: 0,
                    avenant_typemonte: null,
                    avenant_comment: null,
                    avenant_justification: null,
                    avenant_status: status.avenantstatus_id,
					avenant_horse: null,
					avenant_transfert: 0,
                    avenant_valide: 1
                })
            })
            return contract_id
        },

        addContractOnline: async function(){
            const url = Constants.CONTRACT_ADD_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::addContractOnline", url)
			.catch(error => {
				console.error("ContractMixin::addContractOnline => ERROR", error)
				return null
			})

            if(response) {
                return response.retour
            }
            return null
        },

        addContractOnlineFromHorse: async function(horse_id){
            if(horse_id < 0) {
                await this.$sync.force(false)
                horse_id = this.$sync.replaceWithReplicated('horse', horse_id)
            }
            const url = this.constructRoute(Constants.CONTRACT_ADD_FROM_HORSE_URL, {horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::addContractOnlineFromHorse", url)
			.catch(error => {
				console.error("ContractMixin::addContractOnlineFromHorse => ERROR", error)
				return null
			})

            if(response) {
                return response.retour
            }
            return null
        },

        addContractOnlineFromTiers: async function(tiers_id){
            const url = this.constructRoute(Constants.CONTRACT_ADD_FROM_TIERS_URL, {tiers_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::addContractOnlineFromTiers", url)
			.catch(error => {
				console.error("ContractMixin::addContractOnlineFromTiers => ERROR", error)
				return null
			})

            if(response) {
                return response.retour
            }
            return null
        },

        duplicateContractOnline: async function(contract_id){
            const url = this.constructRoute(Constants.CONTRACT_DUPLICATE_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::duplicateContractOnline", url)
			.catch(error => {
				console.error("ContractMixin::duplicateContractOnline => ERROR", error)
				return null
			})

            if(response) {
                return response.retour
            }
            return null
        },

        saveTiersContract: async function(contract_id, tiers_id){

            //Je supprime pour inserer de nouveau
            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)
            tiers_id = this.$sync.replaceWithReplicated('tiers', tiers_id)

            await this.$storage.db.t('contract_tiers')
            .then(table => {
                return table.where({
                    contracttiers_contract: parseInt(contract_id),
				})
				.invalid()
            })

            const contractiers_id = await this.$storage.db.t('contract_tiers')
            .then(table => {
                return table.add({
                    contracttiers_id: Common.getNegativeId(),
                    contracttiers_tiers: parseInt(tiers_id),
                    contracttiers_contract: parseInt(contract_id),
                    contracttiers_valide: 1
                })
            })

            return contractiers_id
        },

        checkHorseTiers : async function(tiers_id, horse_id) {
            //Je vais update le tiers_horse pour le mettre dispo dans l'espace client 
            if(horse_id) {
                let horses = await this.$storage.db.t('tiers')
                .then(table => {
                    return table.get(parseInt(tiers_id))
                }).then(tier => {
                    return TierTransformer.process(tier, 'withTiersHorseCurrentPart')
                })

                if(horses.tiers_horse) {

                    let horse = horses.tiers_horse.filter(h => h.tiershorse_horse == horse_id)
                    if(horse) {
                        this.$storage.db.t('horse')
                        .then(table => {
                            return table.update(parseInt(horse_id), {
                                horse_display_ca: true
                            })
                        })
                    }
                }
            }
        },

        getAvenantArticles: async function(avenant_id){
			avenant_id = this.$sync.replaceWithReplicated('contract_avenant', avenant_id)

			const avenantTable = await this.$storage.db.t('contract_avenant')
            const avenant = await avenantTable.get(parseInt(avenant_id))

            const all_avenants = await avenantTable.where('avenant_contract').equals(avenant.avenant_contract).toArray()

            let conditions = []
            for (let i = 0; i < all_avenants.length; i++) {
                const conditions_articles = await this.$storage.db.t('contract_avenant_articles')
                .then(table => {
                    return table.where({
                        avenantarticles_avenant: parseInt(all_avenants[i].avenant_id)
                    })
                })
                .then(col => {
                    return col.transform(new ContractAvenantArticlesTransformer())
                })

                if(conditions_articles.length > 0){
                    conditions.push(conditions_articles)
                }
            }

            return conditions
        },

        getAvenantArticlesOnline: async function(avenant_id) {
            if(avenant_id < 0){
                await this.$sync.force(true)
                avenant_id = this.$sync.replaceWithReplicated('avenant_id', avenant_id)
            }

            const params = { 'avenant_id': avenant_id }
            const url = this.constructRoute(Constants.CONTRACT_AVENANT_ARTICLES, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixins::getAvenantArticlesOnline", url)
            .catch(error => {
                console.error("ContractMixins::getAvenantArticlesOnline => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        saveContract: async function(contract_id, form, contractconfig_id, avenant_id, isDisabled)
        {
            let contract = {
                contract_courtier: Object.keys(form.courtier).length > 0 ? form.courtier.tiers_id : null,
                contract_note: form.contract_note,
                contract_contractconfig: contractconfig_id,
                contract_paillette: form.paillette,
                contract_created: form.contract_created ? form.contract_created : new Date()
            }

            await this.$storage.db.t('contract')
            .then(table => {
                return table.update(parseInt(contract_id), contract)
            })
            
            if(isDisabled == false)
            {
                let contract_avenant = {
                    avenant_justification: form.avenant_justification,
                    avenant_comment: form.avenant_comment,
                    avenant_cmep: Object.keys(form.cmep).length > 0 ? form.cmep.tiers_id : null,
                    avenant_contract: contract_id,
                    avenant_type: 1, //Avenant initial
                    avenant_typemonte: form.type_monte.id,
                    avenant_status: 1, //Brouillon
                    avenant_valide: 1,
                    avenant_free: form.avenant_free,
                    avenant_dps: form.avenant_dps,
                    avenant_dn_bool: form.avenant_dn_bool,
                    avenant_as_bool: form.avenant_as_bool,
                    avenant_revue_bool: form.avenant_revue_bool,
                    avenant_dn: form.avenant_dn,
                    avenant_model: form.modele.model_id,
					avenant_horse: form.jument.horse_id,
					avenant_transfert: form.avenant_transfert ? 1 : 0,
					avenant_porteuse: form.porteuse ? form.porteuse.horse_id : null
				}

                await this.$storage.db.t('contract_avenant')
                .then(table => {
                    return table.update(parseInt(avenant_id), contract_avenant)
                })
                ContractAvenantCleaner.inst().onMutation([avenant_id], ['avenant'])
            } else {
                let contract_avenant = {
                    avenant_model: form.modele.model_id,
                    avenant_comment: form.avenant_comment,
                    avenant_type: 1,
                    avenant_cmep: Object.keys(form.cmep).length > 0 ? form.cmep.tiers_id : null,
                    avenant_dps: form.avenant_dps,
                    avenant_dn_bool: form.avenant_dn_bool,
                    avenant_as_bool: form.avenant_as_bool,
                    avenant_dn: form.avenant_dn,
                    avenant_porteuse: form.porteuse ? form.porteuse.horse_id : null
				}

                await this.$storage.db.t('contract_avenant')
                .then(table => {
                    return table.update(parseInt(avenant_id), contract_avenant)
                })

                ContractAvenantCleaner.inst().onMutation([avenant_id], ['avenant'])
            }
        },

        saveContractOnline: async function(contract_id, form, contractconfig_id, avenant_id, avenant_article, isDisabled, validation = false)
        {
            if((form.jument && form.jument.horse_id && form.jument.horse_id < 0) || (form.porteuse && form.porteuse.horse_id && form.porteuse.horse_id < 0)) {
                await this.$sync.force()
            }

            let contract = null
            if(!validation) {
                contract = {
                    ...((form.courtier && Object.keys(form.courtier).length > 0) && {contract_courtier: form.courtier.tiers_id}),
                    ...(form.contract_note !== undefined && {contract_note: form.contract_note}),
                    contract_contractconfig: contractconfig_id,
                    ...(form.paillette !== undefined && {contract_paillette: form.paillette}),
                    ...(form.contract_created !== undefined && {contract_created: form.contract_created})
                }
            }
            
            let contract_avenant = {
                avenant_id: avenant_id,
                ...(form.avenant_justification !== undefined && {avenant_justification: form.avenant_justification}),
                ...(form.avenant_comment !== undefined && {avenant_comment: form.avenant_comment}),
                ...((form.cmep && Object.keys(form.cmep).length > 0) && {avenant_cmep: form.cmep.tiers_id}),
                avenant_contract: contract_id,
                ...(form.avenant_type !== undefined && {avenant_type: form.avenant_type}),
                ...((form.type_monte && form.type_monte.id !== undefined) && {avenant_typemonte: form.type_monte.id}),
                ...(form.avenant_free !== undefined && {avenant_free: form.avenant_free}),
                ...(form.avenant_dps !== undefined && {avenant_dps: form.avenant_dps}),
                ...(form.avenant_dn_bool !== undefined && {avenant_dn_bool: form.avenant_dn_bool}),
                ...(form.avenant_as_bool !== undefined && {avenant_as_bool: form.avenant_as_bool}),
                ...(form.avenant_revue_bool !== undefined && {avenant_revue_bool: form.avenant_revue_bool}),
                ...(form.avenant_dn !== undefined && {avenant_dn: form.avenant_dn}),
                ...((form.modele && form.modele.model_id !== undefined) && {avenant_model: form.modele.model_id}),
                // ...((form.jument && form.jument.horse_id !== undefined) && {avenant_horse: parseInt(this.$sync.replaceWithReplicated('horse', form.jument.horse_id))}),
                // ...((form.porteuse && form.porteuse.horse_id) && {avenant_porteuse: parseInt(this.$sync.replaceWithReplicated('horse', form.porteuse.horse_id))}),
                ...((form.jument_id) && {avenant_horse: form.jument_id}),
                ...((form.porteuse_id) && {avenant_porteuse: form.porteuse_id}),
                ...(form.signature_date && {avenant_date_signature: form.signature_date}),
                ...(form.validation_date && {avenant_date_validation: form.validation_date}),
                ...(form.type_contrat && {avenant_type_contrat: form.type_contrat}),
                ...((isDisabled && form.status !== undefined) && {avenant_status: form.status}),
                ...((!isDisabled) && {avenant_status: 1}),
                ...((form.avenant_transfert || !form.avenant_transfert) && {avenant_transfert: form.avenant_transfert})
            }

            let params = {
                contract,
                contract_avenant
            }

            if(!validation) {
                params.avenants_articles = await this.saveAvenantArticlesV2(avenant_id, avenant_article, contract_id, false)
            }

            const url = this.constructRoute(Constants.CONTRACT_SAVE_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_post_api("ContractMixin::saveContractOnline", url, params, false)
			.catch(error => {
				console.error("ContractMixin::saveContractOnline => ERROR", error)
				return null
			})

			if(response) {
                ContractAvenantCleaner.inst().onMutation([avenant_id], ['avenant', 'fractions'])
                return response.retour
                // this.updateContract([contract_id])
            }
        },

        createAvenant: async function(current_avenant_id, avenant_type, contract_id, contractconfig_id, infos, avenant_date, avenant_transfert){
            //je vais récuperer l'avenant courant pour avoir toutes les infos
            let avenant = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.get(parseInt(current_avenant_id))
            })
            .then(item => {
                return ContractAvenantTransformer.process(item)
            })

            let new_avenant = {}

            switch(avenant_type){
                case 'etalon' :
                    new_avenant = {
                        avenant_id: Common.getNegativeId(),
                        avenant_num: (avenant.avenant_num * 1) + 1,
                        avenant_justification: avenant.avenant_justification,
                        avenant_comment: avenant.avenant_comment,
                        avenant_contract: contract_id,
                        avenant_cmep: avenant.avenant_cmep,
                        avenant_type: 3,
                        avenant_status: avenant.avenant_status,
                        avenant_free: avenant.avenant_free,
                        avenant_date: avenant_date,
						avenant_horse: avenant.avenant_horse,
						avenant_transfert: avenant_transfert ? 1 : 0,

                        avenant_model: infos.modele.model_id,
                        avenant_typemonte: infos.type_monte.id,
                        avenant_valide: 1,

                        avenant_date_signature: avenant.avenant_date_signature,
                        avenant_date_validation: avenant.avenant_date_validation,
                        avenant_dps: avenant.avenant_dps,
                        avenant_type_contrat: avenant.avenant_type_contrat,
						avenant_porteuse: avenant.avenant_porteuse
                    }
                break
                case 'type_monte' :
                    new_avenant = {
                        avenant_id: Common.getNegativeId(),
                        avenant_num: (avenant.avenant_num * 1) + 1,
                        avenant_justification: avenant.avenant_justification,
                        avenant_comment: avenant.avenant_comment,
                        avenant_contract: contract_id,
                        avenant_cmep: avenant.avenant_cmep,
                        avenant_type: 3,
                        avenant_status: avenant.avenant_status,
                        avenant_free: avenant.avenant_free,
                        avenant_date: avenant_date,
                        avenant_horse: avenant.avenant_horse,
						avenant_transfert: avenant_transfert ? 1 : 0,

                        avenant_model: infos.modele.model_id,
                        avenant_typemonte: infos.type_monte.id,
                        avenant_valide: 1,

                        avenant_date_signature: avenant.avenant_date_signature,
                        avenant_date_validation: avenant.avenant_date_validation,
                        avenant_dps: avenant.avenant_dps,
                        avenant_type_contrat: avenant.avenant_type_contrat,
						avenant_porteuse: avenant.avenant_porteuse
                    }
                break
                case 'saison' :
                    new_avenant = {
                        avenant_id: Common.getNegativeId(),
                        avenant_num: (avenant.avenant_num * 1) + 1,
                        avenant_justification: avenant.avenant_justification,
                        avenant_comment: avenant.avenant_comment,
                        avenant_contract: contract_id,
                        avenant_cmep: avenant.avenant_cmep,
                        avenant_type: 3,
                        avenant_status: avenant.avenant_status,
                        avenant_free: avenant.avenant_free,
                        avenant_date: avenant_date,
                        avenant_horse: avenant.avenant_horse,
						avenant_transfert: avenant_transfert ? 1 : 0,

                        avenant_model: infos.modele.model_id,
                        avenant_typemonte: infos.type_monte.id,
                        avenant_valide: 1,

                        avenant_date_signature: avenant.avenant_date_signature,
                        avenant_date_validation: avenant.avenant_date_validation,
                        avenant_dps: avenant.avenant_dps,
                        avenant_type_contrat: avenant.avenant_type_contrat,
						avenant_porteuse: avenant.avenant_porteuse
                    }
                break
                case 'jument' :
                    new_avenant = {
                        avenant_id: Common.getNegativeId(),
                        avenant_num: (avenant.avenant_num * 1) + 1,
                        avenant_justification: avenant.avenant_justification,
                        avenant_comment: avenant.avenant_comment,
                        avenant_contract: contract_id,
                        avenant_cmep: avenant.avenant_cmep,
                        avenant_type: 4,
                        avenant_status: avenant.avenant_status,
                        avenant_free: avenant.avenant_free,
                        avenant_date: avenant_date,
                        avenant_horse: infos.mare.horse_id,
						avenant_transfert: avenant_transfert ? 1 : 0,

                        avenant_model: avenant.avenant_model,
                        avenant_typemonte: avenant.avenant_typemonte,
                        avenant_valide: 1,

                        avenant_date_signature: avenant.avenant_date_signature,
                        avenant_date_validation: avenant.avenant_date_validation,
                        avenant_dps: avenant.avenant_dps,
                        avenant_type_contrat: avenant.avenant_type_contrat,
						avenant_porteuse: (infos.porteuse != null) ? infos.porteuse.horse_id : null
                    }
                break
                case 'cmep' :
                    new_avenant = {
                        avenant_id: Common.getNegativeId(),
                        avenant_num: (avenant.avenant_num * 1) + 1,
                        avenant_justification: avenant.avenant_justification,
                        avenant_comment: avenant.avenant_comment,
                        avenant_contract: contract_id,
                        avenant_cmep: infos.cmep.tiers_id,
                        avenant_type: 5,
                        avenant_status: avenant.avenant_status,
                        avenant_free: avenant.avenant_free,
                        avenant_date: avenant_date,
                        avenant_horse: avenant.avenant_horse,
						avenant_transfert: avenant_transfert ? 1 : 0,

                        avenant_model: avenant.avenant_model,
                        avenant_typemonte: avenant.avenant_typemonte,
                        avenant_valide: 1,

                        avenant_date_signature: avenant.avenant_date_signature,
                        avenant_date_validation: avenant.avenant_date_validation,
                        avenant_dps: avenant.avenant_dps,
                        avenant_type_contrat: avenant.avenant_type_contrat,
						avenant_porteuse: avenant.avenant_porteuse
                    }
                break
                case 'annulation' :
                    new_avenant = {
                        avenant_id: Common.getNegativeId(),
                        avenant_num: (avenant.avenant_num * 1) + 1,
                        avenant_justification: avenant.avenant_justification,
                        avenant_comment: avenant.avenant_comment,
                        avenant_contract: contract_id,
                        avenant_cmep: avenant.avenant_cmep,
                        avenant_type: 7,
                        avenant_status: 4,
                        avenant_free: avenant.avenant_free,
                        avenant_date: avenant_date,
                        avenant_horse: avenant.avenant_horse,
                        avenant_transfert: avenant_transfert ? 1 : 0,
                        avenant_model: avenant.avenant_model,
                        avenant_typemonte: avenant.avenant_typemonte,
                        avenant_valide: 1,

                        avenant_date_signature: avenant.avenant_date_signature,
                        avenant_date_validation: avenant.avenant_date_validation,
                        avenant_dps: avenant.avenant_dps,
                        avenant_type_contrat: avenant.avenant_type_contrat,
						avenant_porteuse: avenant.avenant_porteuse
                    }
                break
            }

            const avenant_id = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.add(new_avenant)
            })

            let contract = {
                contract_contractconfig: parseInt(contractconfig_id)
            }

            await this.$storage.db.t('contract')
            .then(table => {
                return table.update(parseInt(contract_id), contract)
            })

            ContractAvenantCleaner.inst().onMutation([avenant_id], ['avenant'])

            return avenant_id

        },

        createAvenantOnline: async function(current_avenant_id, avenant_type, contract_id, contractconfig_id, infos, avenant_date, avenant_transfert) {
            const params = {
                avenant_type: avenant_type,
                contract_id: contract_id,
                contractconfig_id: contractconfig_id,
                infos: infos,
                avenant_date: avenant_date,
                avenant_transfert: avenant_transfert
            }

            const url = this.constructRoute(Constants.CONTRACT_AVENANT_ADD_URL, { 'avenant_id': current_avenant_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ContractMixin::createAvenantOnline", url, params, false)
			.catch(error => {
				console.error("ContractMixin::createAvenantOnline => ERROR", error)
				return null
			})

			if(result) return result.retour
			return null
        },

        saveAvenantArticles: async function(avenant_id, conditions, contract_id){
            //Je vais chercher tous les avenants du contrat avec l'avenant id donné
            let avenant = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.get(parseInt(avenant_id))
            })
            .then(item => {
                return ContractAvenantTransformer.process(item, 'light')
            })

            // let all_avenants = avenant.contract.avenant
            // for (let i = 0; i < all_avenants.length; i++) {

            //     all_avenants[i].avenant_id = this.$sync.replaceWithReplicated('contract_avenant', all_avenants[i].avenant_id)

            //     await this.$storage.db.t('contract_avenant_articles')
            //     .then(table => {
            //         return table.where({
            //             avenantarticles_avenant: parseInt(all_avenants[i].avenant_id)
            //         })
			// 		.filter(avenant_article => (avenant_article.avenantarticles_invoiceable === false))
			// 		.invalid()
            //     })
            // }

            let avenant_articles_to_insert = []

            for (let i = 0; i < conditions.length; i++) {

                let articles_id = conditions[i].articles_id
                let author_id = conditions[i].initial_author
                //Je regarde si l'emetteur est le même que celui lié à l'article
                if(conditions[i].initial_author != conditions[i].author.tiers_id)
                {
                    author_id = conditions[i].author.tiers_id
                    let accounting_plan_dest = await this.loadAccountingPlanByEntity(conditions[i].author.tiers_id)

                    let accounting_account_dest = undefined

                    let accounting_account_dest_fdvex = undefined
                    let create_accounting_account_dest_fdvex = false

                    let accounting_account_dest_fdvxp = undefined
                    let create_accounting_account_dest_fdvxp = false

                    let vat_account_dest = undefined
                    let create_vat_account_dest = false

                    let vat_account_dest_fdvex = undefined
                    let create_vat_account_dest_fdvex = false

                    let vat_account_dest_fdvxp = undefined
                    let create_vat_account_dest_fdvxp = false
                    let accounting_account_dest_tab = []

                    if(accounting_plan_dest !== undefined)
                    {
                        accounting_account_dest = await this.loadAccountingAccounts(accounting_plan_dest.accountingplan_id)
                        accounting_account_dest_tab = accounting_account_dest
                    }

                    let accounting_account_from = await this.loadAccountingAccount(conditions[i].accountingaccount_id)
                    let accounting_account_from_fdvex = await this.loadAccountingAccount(conditions[i].accountingaccount_fdvex_id)
                    let accounting_account_from_fdvxp = await this.loadAccountingAccount(conditions[i].accountingaccount_fdvxp_id)

                    let vat_account_from = await this.loadAccountingAccount(conditions[i].accountingaccount_vat_id)
                    let vat_account_from_fdvex = await this.loadAccountingAccount(conditions[i].accountingaccount_vat_fdvex_id)
                    let vat_account_from_fdvxp = await this.loadAccountingAccount(conditions[i].accountingaccount_vat_fdvxp_id)

                    //Je regarde si j'ai mon compte comptable
                    if(accounting_account_dest !== undefined)
                    {

                        accounting_account_dest = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from.accountingaccount_number && elem.accountingaccount_label == accounting_account_from.accountingaccount_label)


                        //FDVEX
                        accounting_account_dest_fdvex = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from_fdvex.accountingaccount_number && elem.accountingaccount_label == accounting_account_from_fdvex.accountingaccount_label)

                        if(accounting_account_dest != undefined && accounting_account_dest_fdvex != undefined && accounting_account_dest_fdvex != accounting_account_dest)
                        {
                            create_accounting_account_dest_fdvex = true
                        }

                        //FDVXP
                        accounting_account_dest_fdvxp = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from_fdvxp.accountingaccount_number && elem.accountingaccount_label == accounting_account_from_fdvxp.accountingaccount_label)

                        if(accounting_account_dest != undefined && accounting_account_dest_fdvxp != undefined && accounting_account_dest_fdvxp != accounting_account_dest)
                        {
                            create_accounting_account_dest_fdvxp = true
                        }

                        //VAT Account
                        vat_account_dest = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == vat_account_from.accountingaccount_number && elem.accountingaccount_label == vat_account_from.accountingaccount_label)

                        if(accounting_account_dest != undefined && vat_account_dest != undefined && vat_account_dest != accounting_account_dest)
                        {
                            create_vat_account_dest = true
                        }



                        //VAT Account FDVEX
                        vat_account_dest_fdvex = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == vat_account_from_fdvex.accountingaccount_number && elem.accountingaccount_label == vat_account_from_fdvex.accountingaccount_label)

                        if(accounting_account_dest != undefined && vat_account_dest_fdvex != undefined && vat_account_dest_fdvex != accounting_account_dest)
                        {
                            create_vat_account_dest_fdvex = true
                        }


                        //VAT Account FDVXP
                        vat_account_dest_fdvxp = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from_fdvxp.accountingaccount_number && elem.accountingaccount_label == accounting_account_from_fdvxp.accountingaccount_label)

                        if(accounting_account_dest != undefined && vat_account_dest_fdvxp != undefined && vat_account_dest_fdvxp != accounting_account_dest)
                        {
                            create_vat_account_dest_fdvxp = true
                        }
                    }



                    let accountingaccount_id = null
                    let accountingaccount_fdvex_id = null
                    let accountingaccount_fdvxp_id = null
                    let vataccount_id = null
                    let vataccount_fdvex_id = null
                    let vataccount_fdvxp_id = null
                    if(accounting_account_dest == undefined)
                    {
                        //J'ajoute le compte comptable
                        accountingaccount_id = await this.$storage.db.t('accounting_account')
                        .then(table => {
                            return table.add({
                                accountingaccount_id: Common.getNegativeId(),
                                accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                accountingaccount_label: accounting_account_from.accountingaccount_label,
                                accountingaccount_number: accounting_account_from.accountingaccount_number,
                                accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                accountingaccount_valide: 1
                            })
                        })

                        if(create_accounting_account_dest_fdvex)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            accountingaccount_fdvex_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            accountingaccount_fdvex_id = accountingaccount_id
                        }

                        if(create_accounting_account_dest_fdvxp)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            accountingaccount_fdvxp_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            accountingaccount_fdvxp_id = accountingaccount_id
                        }

                        if(create_vat_account_dest)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            vataccount_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            vataccount_id = accountingaccount_id
                        }

                        if(create_vat_account_dest_fdvex)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            vataccount_fdvex_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            vataccount_fdvex_id = accountingaccount_id
                        }

                        if(create_vat_account_dest_fdvxp)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            vataccount_fdvxp_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            vataccount_fdvxp_id = accountingaccount_id
                        }
                    }
                    else
                    {
                        accountingaccount_id = accounting_account_dest.accountingaccount_id
                    }


                    //Je vais regarder si l'auteur a l'article en question et le compte comptable lié
                    let articles = await this.loadArticlesByEntity(conditions[i].author.tiers_id)

                    const article_to_copy = articles.find(elem => elem.articles_code == conditions[i].articles_code)

                    let articles_from = await this.loadArticle(conditions[i].articles_id)

                    //On créer/modifie l'article avec le prix initial et on créer aussi le compte comptable s'il est existe pas
                    if(article_to_copy == undefined)
                    {
                        //

                        articles_id = await this.$storage.db.t('articles')
                        .then(table => {
                            return table.add({
                                articles_id: Common.getNegativeId(),
                                articles_accountingaccount: parseInt(accountingaccount_id),
                                articles_code: articles_from.articles_code,
                                articles_ht: (articles_from.articles_ht * 100).toFixed(2),
                                articles_ttc: (articles_from.articles_ttc * 100).toFixed(2),
                                articles_label: articles_from.articles_label,
                                articles_vat: articles_from.articles_vat,
                                articles_valide: 1
                            })
                        })
                    }
                    else {
                        articles_id = article_to_copy.articles_id
                    }
                    await this.$sync.force(true, true)
                    articles_id = this.$sync.replaceWithReplicated('articles', articles_id)
                }

                //Je ne duplique pas les article deja facturé car sinon ils se retrouvent en double
                if(conditions[i].avenantarticles_invoiceable == 0) {
                    const contractconfigconditions_id = (conditions[i].contractconfigconditions_id != null) ? parseInt(conditions[i].contractconfigconditions_id) : null
                    const avenantarticles_type_condition = (conditions[i].avenantarticles_type_condition != null) ? parseInt(conditions[i].avenantarticles_type_condition) : null

                    let discount_value = conditions[i].avenantarticles_discount_value.toString().replace(',', '.').replace(/\s/g, '') * 100
                    let discount_type = (conditions[i].avenantarticles_discount_type) ? conditions[i].avenantarticles_discount_type.invoicediscounttype_id : null
                    if(discount_value == 0) {
                        discount_value = null
                        discount_type = null
                    }

                    //On recalcul le ttc ici pour pas avoir de soucis à l'envoi à l'api
                    const ht = (conditions[i].avenantarticles_ht.replace(',', '.').replace(/\s/g, '') * 100)
                    const ttc = ht * parseInt((1 + conditions[i].vat_value)*1000)

                    avenant_articles_to_insert.push({
                        // avenantarticles_id: Common.getNegativeId(),
                        avenantarticles_conditions: contractconfigconditions_id,
                        avenantarticles_article: parseInt(articles_id),
                        avenantarticles_tiers: parseInt(conditions[i].tiers.tiers_id),
                        avenantarticles_author: parseInt(author_id),
                        avenantarticles_avenant: parseInt(avenant_id),
                        avenantarticles_invoiceable: conditions[i].avenantarticles_invoiceable,
                        avenantarticles_ht: ht,
                        avenantarticles_ttc: parseInt(ttc/1000),
                        avenantarticles_valide: 1,
                        avenantarticles_type_condition: avenantarticles_type_condition,
                        avenantarticles_qte: conditions[i].avenantarticles_qte,
                        avenantarticles_quotepart: conditions[i].avenantarticles_quotepart.toString().replace(',', '.').replace(/\s/g, ''),
                        avenantarticles_ht_initial: (conditions[i].avenantarticles_ht_initial.toString().replace(',', '.').replace(/\s/g, '') * 100),
                        avenantarticles_htunit: (conditions[i].avenantarticles_htunit.toString().replace(',', '.').replace(/\s/g, '') * 100),
                        avenantarticles_htunit_before_discount: (conditions[i].avenantarticles_htunit_before_discount.toString().replace(',', '.').replace(/\s/g, '') * 100),
                        avenantarticles_ht_before_discount: (conditions[i].avenantarticles_ht_before_discount.toString().replace(',', '.').replace(/\s/g, '') * 100),
                        avenantarticles_discount_type: discount_type,
                        avenantarticles_discount_value: discount_value,
                        avenantarticles_comment: conditions[i].avenantarticles_comment
                    })
                }
            }


            const params = { 'contract_id': contract_id, 'avenant_id': avenant_id }
            const url = this.constructRoute(Constants.CONTRACT_SAVE_AVENANT_ARTICLES, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::saveAvenantArticles", url, {
                avenants_articles: avenant_articles_to_insert,
                delete_other: true,
            }, false)
            .catch(error => {
                console.error("ContractMixins::saveAvenantArticles => ERROR", error)
                return null
            })

            ContractAvenantCleaner.inst().onMutation([avenant_id], ['fractions'])
            this.updateContract([contract_id])
        },

        saveAvenantArticlesV2: async function(avenant_id, conditions, contract_id, call_api = true){
            let avenant_articles_to_insert = []

            for (let i = 0; i < conditions.length; i++) {

                let articles_id = conditions[i].articles_id
                let author_id = conditions[i].initial_author
                //Je regarde si l'emetteur est le même que celui lié à l'article
                if(conditions[i].initial_author != conditions[i].author.tiers_id)
                {
                    author_id = conditions[i].author.tiers_id
                    let accounting_plan_dest = await this.loadAccountingPlanByEntity(conditions[i].author.tiers_id)

                    let accounting_account_dest = undefined

                    let accounting_account_dest_fdvex = undefined
                    let create_accounting_account_dest_fdvex = false

                    let accounting_account_dest_fdvxp = undefined
                    let create_accounting_account_dest_fdvxp = false

                    let vat_account_dest = undefined
                    let create_vat_account_dest = false

                    let vat_account_dest_fdvex = undefined
                    let create_vat_account_dest_fdvex = false

                    let vat_account_dest_fdvxp = undefined
                    let create_vat_account_dest_fdvxp = false
                    let accounting_account_dest_tab = []

                    if(accounting_plan_dest !== undefined)
                    {
                        accounting_account_dest = await this.loadAccountingAccounts(accounting_plan_dest.accountingplan_id)
                        accounting_account_dest_tab = accounting_account_dest
                    }

                    let accounting_account_from = await this.loadAccountingAccount(conditions[i].accountingaccount_id)
                    let accounting_account_from_fdvex = await this.loadAccountingAccount(conditions[i].accountingaccount_fdvex_id)
                    let accounting_account_from_fdvxp = await this.loadAccountingAccount(conditions[i].accountingaccount_fdvxp_id)

                    let vat_account_from = await this.loadAccountingAccount(conditions[i].accountingaccount_vat_id)
                    let vat_account_from_fdvex = await this.loadAccountingAccount(conditions[i].accountingaccount_vat_fdvex_id)
                    let vat_account_from_fdvxp = await this.loadAccountingAccount(conditions[i].accountingaccount_vat_fdvxp_id)

                    //Je regarde si j'ai mon compte comptable
                    if(accounting_account_dest !== undefined)
                    {

                        accounting_account_dest = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from.accountingaccount_number && elem.accountingaccount_label == accounting_account_from.accountingaccount_label)


                        //FDVEX
                        accounting_account_dest_fdvex = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from_fdvex.accountingaccount_number && elem.accountingaccount_label == accounting_account_from_fdvex.accountingaccount_label)

                        if(accounting_account_dest != undefined && accounting_account_dest_fdvex != undefined && accounting_account_dest_fdvex != accounting_account_dest)
                        {
                            create_accounting_account_dest_fdvex = true
                        }

                        //FDVXP
                        accounting_account_dest_fdvxp = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from_fdvxp.accountingaccount_number && elem.accountingaccount_label == accounting_account_from_fdvxp.accountingaccount_label)

                        if(accounting_account_dest != undefined && accounting_account_dest_fdvxp != undefined && accounting_account_dest_fdvxp != accounting_account_dest)
                        {
                            create_accounting_account_dest_fdvxp = true
                        }

                        //VAT Account
                        vat_account_dest = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == vat_account_from.accountingaccount_number && elem.accountingaccount_label == vat_account_from.accountingaccount_label)

                        if(accounting_account_dest != undefined && vat_account_dest != undefined && vat_account_dest != accounting_account_dest)
                        {
                            create_vat_account_dest = true
                        }



                        //VAT Account FDVEX
                        vat_account_dest_fdvex = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == vat_account_from_fdvex.accountingaccount_number && elem.accountingaccount_label == vat_account_from_fdvex.accountingaccount_label)

                        if(accounting_account_dest != undefined && vat_account_dest_fdvex != undefined && vat_account_dest_fdvex != accounting_account_dest)
                        {
                            create_vat_account_dest_fdvex = true
                        }


                        //VAT Account FDVXP
                        vat_account_dest_fdvxp = accounting_account_dest_tab.find(elem => elem.accountingaccount_number == accounting_account_from_fdvxp.accountingaccount_number && elem.accountingaccount_label == accounting_account_from_fdvxp.accountingaccount_label)

                        if(accounting_account_dest != undefined && vat_account_dest_fdvxp != undefined && vat_account_dest_fdvxp != accounting_account_dest)
                        {
                            create_vat_account_dest_fdvxp = true
                        }
                    }



                    let accountingaccount_id = null
                    let accountingaccount_fdvex_id = null
                    let accountingaccount_fdvxp_id = null
                    let vataccount_id = null
                    let vataccount_fdvex_id = null
                    let vataccount_fdvxp_id = null
                    if(accounting_account_dest == undefined)
                    {
                        //J'ajoute le compte comptable
                        accountingaccount_id = await this.$storage.db.t('accounting_account')
                        .then(table => {
                            return table.add({
                                accountingaccount_id: Common.getNegativeId(),
                                accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                accountingaccount_label: accounting_account_from.accountingaccount_label,
                                accountingaccount_number: accounting_account_from.accountingaccount_number,
                                accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                accountingaccount_valide: 1
                            })
                        })

                        if(create_accounting_account_dest_fdvex)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            accountingaccount_fdvex_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            accountingaccount_fdvex_id = accountingaccount_id
                        }

                        if(create_accounting_account_dest_fdvxp)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            accountingaccount_fdvxp_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            accountingaccount_fdvxp_id = accountingaccount_id
                        }

                        if(create_vat_account_dest)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            vataccount_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            vataccount_id = accountingaccount_id
                        }

                        if(create_vat_account_dest_fdvex)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            vataccount_fdvex_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            vataccount_fdvex_id = accountingaccount_id
                        }

                        if(create_vat_account_dest_fdvxp)
                        {
                            //Je pars du principe que je n'en ai aucun autre
                            vataccount_fdvxp_id = await this.$storage.db.t('accounting_account')
                            .then(table => {
                                return table.add({
                                    accountingaccount_id: Common.getNegativeId(),
                                    accountingaccount_accountingplan: parseInt(accounting_plan_dest.accountingplan_id),
                                    accountingaccount_label: accounting_account_from.accountingaccount_label,
                                    accountingaccount_number: accounting_account_from.accountingaccount_number,
                                    accountingaccount_vat: accounting_account_from.accountingaccount_vat,
                                    accountingaccount_valide: 1
                                })
                            })
                        }
                        else
                        {
                            vataccount_fdvxp_id = accountingaccount_id
                        }
                    }
                    else
                    {
                        accountingaccount_id = accounting_account_dest.accountingaccount_id
                    }


                    //Je vais regarder si l'auteur a l'article en question et le compte comptable lié
                    let articles = await this.loadArticlesByEntity(conditions[i].author.tiers_id)

                    const article_to_copy = articles.find(elem => elem.articles_code == conditions[i].articles_code)

                    let articles_from = await this.loadArticle(conditions[i].articles_id)

                    //On créer/modifie l'article avec le prix initial et on créer aussi le compte comptable s'il est existe pas
                    if(article_to_copy == undefined)
                    {
                        //

                        articles_id = await this.$storage.db.t('articles')
                        .then(table => {
                            return table.add({
                                articles_id: Common.getNegativeId(),
                                articles_accountingaccount: parseInt(accountingaccount_id),
                                articles_code: articles_from.articles_code,
                                articles_ht: (articles_from.articles_ht * 100).toFixed(2),
                                articles_ttc: (articles_from.articles_ttc * 100).toFixed(2),
                                articles_label: articles_from.articles_label,
                                articles_vat: articles_from.articles_vat,
                                articles_valide: 1
                            })
                        })
                    }
                    else {
                        articles_id = article_to_copy.articles_id
                    }
                    // await this.$sync.force(true, true)
                    articles_id = this.$sync.replaceWithReplicated('articles', articles_id)
                }

                //Je ne duplique pas les article deja facturé car sinon ils se retrouvent en double
                if(conditions[i].avenantarticles_invoiceable == 0) {
                    const contractconfigconditions_id = (conditions[i].contractconfigconditions_id != null) ? parseInt(conditions[i].contractconfigconditions_id) : null
                    const avenantarticles_type_condition = (conditions[i].avenantarticles_type_condition != null) ? parseInt(conditions[i].avenantarticles_type_condition) : null

                    let discount_value = conditions[i].avenantarticles_discount_value.toString().replace(',', '.').replace(/\s/g, '') * 100
                    let discount_type = (conditions[i].avenantarticles_discount_type) ? conditions[i].avenantarticles_discount_type.invoicediscounttype_id : null
                    if(discount_value == 0) {
                        discount_value = null
                        discount_type = null
                    }

                    //On recalcul le ttc ici pour pas avoir de soucis à l'envoi à l'api
                    const ht = parseFloat(conditions[i].avenantarticles_ht_hidden.toString().replace(' ', '').replace(',', '.')) * 100
                    const htunit_before = parseFloat(conditions[i].avenantarticles_htunit_before_discount_hidden.toString().replace(' ', '').replace(',', '.')) * 100
                    const ttc = ht * parseInt((1 + conditions[i].vat_value)*1000)

                    avenant_articles_to_insert.push({
                        // avenantarticles_id: Common.getNegativeId(),
                        avenantarticles_conditions: contractconfigconditions_id,
                        avenantarticles_article: parseInt(articles_id),
                        avenantarticles_tiers: parseInt(conditions[i].tiers.tiers_id),
                        avenantarticles_author: parseInt(author_id),
                        avenantarticles_avenant: parseInt(avenant_id),
                        avenantarticles_invoiceable: conditions[i].avenantarticles_invoiceable,
                        avenantarticles_ht: ht,
                        avenantarticles_ttc: parseInt(ttc/1000),
                        avenantarticles_valide: 1,
                        avenantarticles_type_condition: avenantarticles_type_condition,
                        avenantarticles_qte: conditions[i].avenantarticles_qte,
                        avenantarticles_quotepart: conditions[i].avenantarticles_quotepart.toString().replace(',', '.').replace(/\s/g, ''),
                        avenantarticles_ht_initial: (conditions[i].avenantarticles_ht_initial.toString().replace(',', '.').replace(/\s/g, '') * 100),
                        avenantarticles_htunit: (conditions[i].avenantarticles_htunit.toString().replace(',', '.').replace(/\s/g, '') * 100),
                        avenantarticles_htunit_before_discount: htunit_before,
                        avenantarticles_ht_before_discount: (conditions[i].avenantarticles_ht_before_discount.toString().replace(',', '.').replace(/\s/g, '') * 100),
                        avenantarticles_discount_type: discount_type,
                        avenantarticles_discount_value: discount_value,
                        avenantarticles_comment: conditions[i].avenantarticles_comment
                    })
                }
            }

            //Pour l'annulation de contrat par exemple, on doit supprimer les avenants articles
            if(call_api) {

                const params = { 'contract_id': contract_id, 'avenant_id': avenant_id }
                const url = this.constructRoute(Constants.CONTRACT_SAVE_AVENANT_ARTICLES, params) + "?licence_key="+Constants.USER_LICENCE_KEY
                const result = await this.$request.request_post_api("ContractMixins::saveAvenantArticles", url, {
                    avenants_articles: avenant_articles_to_insert,
                    delete_other: true,
                }, false)
                .catch(error => {
                    console.error("ContractMixins::saveAvenantArticles => ERROR", error)
                    return null
                })
            }
                
            return avenant_articles_to_insert
        },

        //Permet d'ajouter un article uniquement sur le contrat sans qu'il soit dans le contract config #bidouille
        addAvenantArticleSolo: async function(infos, contract_id){
            let ht = infos.ht.toString()
            ht = ht.replace(',', '.')
            ht = ht.replace(/\s/g, '')

            let ttc = infos.ttc.toString()
            ttc = ttc.replace(',', '.')
            ttc = ttc.replace(/\s/g, '')

            let avenant_articles_to_insert = []
            if(infos.horse_id) {
                const horse = await this.loadTiersCurrentPart(infos.horse_id)
                if(horse && horse.tiers_horse.length > 0) {
                    horse.tiers_horse.forEach(th => {
                        avenant_articles_to_insert.push({
                            avenantarticles_conditions: null,
                            avenantarticles_article: parseInt(infos.articles_id),
                            avenantarticles_tiers: parseInt(th.tiers.tiers_id),
                            avenantarticles_author: parseInt(infos.author_id),
                            avenantarticles_avenant: parseInt(infos.avenant_id),
                            avenantarticles_invoiceable: false,
                            avenantarticles_type_condition: infos.conditions,
                            avenantarticles_ht: (ht * 100) * (th.tiers_horse_part.tiershorsepart_contract / 100),
                            avenantarticles_ttc: (ttc * 100),
                            avenantarticles_valide: 1,
                            avenantarticles_qte: 1,
                            avenantarticles_htunit: (ht * 100),
                            avenantarticles_htunit_before_discount: (ht * 100) * (th.tiers_horse_part.tiershorsepart_contract / 100),
                            avenantarticles_ht_before_discount: (ht * 100),
                            avenantarticles_discount_type: null,
                            avenantarticles_discount_value: 0,
                            avenantarticles_comment: infos.comment,
                            avenantarticles_ht_initial: (ht * 100),
                            avenantarticles_quotepart: th.tiers_horse_part.tiershorsepart_contract
                        })
                    })
                }
                else {
                    avenant_articles_to_insert.push({
                        avenantarticles_conditions: null,
                        avenantarticles_article: parseInt(infos.articles_id),
                        avenantarticles_tiers: parseInt(infos.tiers_id),
                        avenantarticles_author: parseInt(infos.author_id),
                        avenantarticles_avenant: parseInt(infos.avenant_id),
                        avenantarticles_invoiceable: false,
                        avenantarticles_type_condition: infos.conditions,
                        avenantarticles_ht: (ht * 100),
                        avenantarticles_ttc: (ttc * 100),
                        avenantarticles_valide: 1,
                        avenantarticles_qte: 1,
                        avenantarticles_htunit: (ht * 100),
                        avenantarticles_htunit_before_discount: (ht * 100),
                        avenantarticles_ht_before_discount: (ht * 100),
                        avenantarticles_discount_type: null,
                        avenantarticles_discount_value: 0,
                        avenantarticles_comment: infos.comment
                    }) 
                }
            }
            else {

            // let avenant = this.$storage.db.t('contract_avenant_type')
            // .then(table => {
            //     return table.toCollection()
            // })
            // .then(col => {
            //     return col.transform(new ContractAvenantTypeTransformer())
            // })
            // await this.$storage.db.t('contract_avenant_articles')
            // .then(table => {

            //     return table.add({
            //         avenantarticles_id: Common.getNegativeId(),
            //         avenantarticles_conditions: null,
            //         avenantarticles_article: parseInt(infos.articles_id),
            //         avenantarticles_tiers: parseInt(infos.tiers_id),
            //         avenantarticles_author: parseInt(infos.author_id),
            //         avenantarticles_avenant: parseInt(infos.avenant_id),
            //         avenantarticles_invoiceable: false,
            //         avenantarticles_type_condition: infos.conditions,
            //         avenantarticles_ht: (ht * 100),
            //         avenantarticles_ttc: (ttc * 100),
            //         avenantarticles_valide: 1,
            //         avenantarticles_qte: 1,
            //         avenantarticles_htunit: (ht * 100),
            //         avenantarticles_htunit_before_discount: (ht * 100),
            //         avenantarticles_ht_before_discount: (ht * 100),
            //         avenantarticles_discount_type: null,
            //         avenantarticles_discount_value: 0,
            //         avenantarticles_comment: infos.comment
            //     })
            // })
                avenant_articles_to_insert.push({
                    avenantarticles_conditions: null,
                    avenantarticles_article: parseInt(infos.articles_id),
                    avenantarticles_tiers: parseInt(infos.tiers_id),
                    avenantarticles_author: parseInt(infos.author_id),
                    avenantarticles_avenant: parseInt(infos.avenant_id),
                    avenantarticles_invoiceable: false,
                    avenantarticles_type_condition: infos.conditions,
                    avenantarticles_ht: (ht * 100),
                    avenantarticles_ttc: (ttc * 100),
                    avenantarticles_valide: 1,
                    avenantarticles_qte: 1,
                    avenantarticles_htunit: (ht * 100),
                    avenantarticles_htunit_before_discount: (ht * 100),
                    avenantarticles_ht_before_discount: (ht * 100),
                    avenantarticles_discount_type: null,
                    avenantarticles_discount_value: 0,
                    avenantarticles_comment: infos.comment
                })
            }

            const params = { 'contract_id': contract_id, 'avenant_id': infos.avenant_id }
            const url = this.constructRoute(Constants.CONTRACT_SAVE_AVENANT_ARTICLES, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::saveAvenantArticles", url, {
                avenants_articles: avenant_articles_to_insert,
                delete_other: false,
            }, false)
            .catch(error => {
                console.error("ContractMixins::saveAvenantArticles => ERROR", error)
                return null
            })

            ContractCleaner.inst().onMutation([contract_id], ['articles'])
            this.updateContract([contract_id])

            return true
        },

        loadJuments: async function(){
            return this.$storage.db.t('horse')
            .then(table => {
				return table
					.where('horse_sexe')
					.equals('F')
                    .and(h => h.horse_inactive == 0)
					.transform(new HorseTransformer())
			})
        },

        loadAvenantType: async function(){
            return this.$storage.db.t('contract_avenant_type')
            .then(table => {
                return table.toCollection()
            })
            .then(col => {
                return col.transform(new ContractAvenantTypeTransformer())
            })
        },

        loadContractArticlesAValider: async function(contract_id){
            let contract = await this.$storage.db.t('contract')
            .then(table => {
                return table.get(parseInt(contract_id))
            })
            .then(item => {
                return ContractTransformer.process(item, 'all')
            })

            let all_avenants = contract.avenant
            let conditions = []
            for (let i = 0; i < all_avenants.length; i++) {

                const conditions_articles = await this.$storage.db.t('contract_avenant_articles')
                .then(table => {
                    return table.where({
                        avenantarticles_avenant: parseInt(all_avenants[i].avenant_id)
                    }).filter(elem => elem.avenantarticles_invoiceable == false)
                })
                .then(col => {
                    return col.transform(new ContractAvenantArticlesTransformer())
                })

                if(conditions_articles.length > 0){
                    for (let j = 0; j < conditions_articles.length; j++) {

                        if(Object.keys(conditions_articles[j].conditions).length > 0)
                        {
                            conditions_articles[j].conditions.contract_conditions.contractconditions_label = this.getTrad(conditions_articles[j].conditions.contract_conditions.contractconditions_label)
                        }
                        else if(conditions_articles[j].conditions_solo !== null && Object.keys(conditions_articles[j].conditions_solo).length > 0)
                        {
                            conditions_articles[j].conditions = {
                                contract_conditions: {}
                            }
                            conditions_articles[j].conditions.contract_conditions.contractconditions_label = this.getTrad(conditions_articles[j].conditions_solo.contractconditions_label)
                        }

                        conditions_articles[j].avenantarticles_ht = await this.priceFormat(conditions_articles[j].avenantarticles_ht / 100)
                        conditions.push(conditions_articles[j])
                    }
                }
            }

            return conditions
        },

        loadContractArticlesAValiderV2: async function(contract_id) {

            const url = this.constructRoute(Constants.CONTRACT_AVENANT_ARTICLES_AVALIDER_GET_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ContractMixin::loadContractArticlesAValiderV2", url)
			.catch(error => {
				console.error("ContractMixin::loadContractArticlesAValiderV2 => ERROR", error)
				return null
			})

			if(response) {
                return response.retour
            }
        },

        editStatusAvenant: async function(avenant_id, status) {
             await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.update(parseInt(avenant_id), {avenant_status: status})
            })

			ContractAvenantCleaner.inst().onMutation([parseInt(avenant_id)], ['avenant'])
        },

        unsignContract: async function(avenant_id) {
            await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.update(parseInt(avenant_id), {
                    avenant_date_signature: null,
                    avenant_date_validation: null,
                    avenant_status: 1,
                })
            })

			ContractAvenantCleaner.inst().onMutation([parseInt(avenant_id)], ['avenant'])
            await this.$sync.force(true, true)
        },

        signatureAvenant: async function(avenant_id, date_signature, date_validation, type_contrat, dps) {
            const param = {
                avenant_date_signature: date_signature,
                avenant_date_validation: date_validation,
                avenant_type_contrat: type_contrat,
                avenant_dps: dps,
            }
            await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.update(parseInt(avenant_id), param)
            })

			ContractAvenantCleaner.inst().onMutation([parseInt(avenant_id)], ['avenant'])
        },

        signatureAvenantOnline: async function(avenant_id, signature_date, validation_date, type_contrat, avenant_dps) {
            const params = {
                signature_date: signature_date,
                validation_date: validation_date,
                type_contrat: type_contrat,
                avenant_dps: avenant_dps
            }

            const url = this.constructRoute(Constants.CONTRACT_AVENANT_SIGN_URL, { avenant_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::signatureAvenantOnline", url, params, false)
            .catch(error => {
                console.error("ContractMixins::signatureAvenantOnline => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        editDateValidationAvenant: async function(avenant_id, date_validation) {
             await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.update(parseInt(avenant_id), {avenant_date_validation: date_validation})
            })

			ContractAvenantCleaner.inst().onMutation([parseInt(avenant_id)], ['avenant'])
        },

        getContractNumber: async function(contract_id) {
            let contract = contract_id
            if(contract_id < 0){
                await this.$sync.force(true)
                contract = this.$sync.replaceWithReplicated('contract', contract_id)
            }
            const params = { 'contract_id': contract }
            const url = this.constructRoute(Constants.CONTRACT_NUMBER, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixins::getContractNumber", url)
            .catch(error => {
                console.error("ContractMixins::getContractNumber => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        saveContractNumber: async function(contract_id, contract_number) {
            await this.$storage.db.t('contract')
            .then(table => {
                return table.update(parseInt(contract_id), {contract_num: contract_number})
            })
        },

        loadSaisonMonte: async function(season_id, invoice, start, end) {
            const url = this.constructRoute(Constants.CONTRACT_BILAN_SAISON_URL, {season_id, start, end}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixins::loadSaisonMonte", url)
            .catch(error => {
                console.error("ContractMixins::loadSaisonMonte => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        savePartContract: async function(contract_id, saillie_id){
            let contract = contract_id
            if(contract_id < 0){
                await this.$sync.force(true)
                contract = this.$sync.replaceWithReplicated('contract', contract_id)
            }

            const params = { 'contract_id': contract, 'saillie_id': saillie_id }
            const url = this.constructRoute(Constants.CONTRACT_SAILLIE, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::savePartContract", url, params, false)
            .catch(error => {
                console.error("ContractMixins::savePartContract => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        freeSaillieContract: async function(id){
            let contract_id = id
            contract_id = this.$sync.replaceWithReplicated('contract', contract_id)

            if(contract_id < 0){
                await this.$sync.force(true)
                contract_id = this.$sync.replaceWithReplicated('contract', contract_id)
            }

            // const saillie = 
            await this.$storage.db.t('saillie')
            .then(table => {
                return table.where({
                    saillie_contract: parseInt(contract_id),
                })
                .modify({ saillie_contract: null })
            })

            // if(saillie)
            // {
            //     await this.$storage.db.t('saillie')
            //      .then(table => {
            //          return table.update(parseInt(saillie.saillie_id), { saillie_contract: null })
            //      })
            // }

            // const params = { 'contract_id': contract_id}
            // const url = this.constructRoute(Constants.CONTRACT_SAILLIE_FREE, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            // const result = await this.$request.request_post_api("ContractMixins::freeSaillieContract", url, params, false)
            // .catch(error => {
            //     console.error("ContractMixins::freeSaillieContract => ERROR", error)
            //     return null
            // })
            // if(result) return result.retour
            // return null
        },

        freeSaillieContractv2: async function(contract_id){
            const params = {'contract_id': contract_id}
            const url = this.constructRoute(Constants.CONTRACT_SAILLIE_FREE, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::freeSaillieContractv2", url, params, false)
            .catch(error => {
                console.error("ContractMixins::freeSaillieContractv2 => ERROR", error)
                return null
            })

            if(result) return result.retour
            return null
        },

        getStatsContract: async function(horse_id, season_id = null){

            let nb_brouillon = 0
            let nb_valides_signes = 0
            let horses = []

            if(season_id == null)
            {
                const season = await this.$storage.db.t('season')
                .then(table => {
                    return table.toCollection()
                    .filter(season => (season.season_default == 1))
                    .first()
                })

                season_id = season.season_id
            }

            /*Calcul du nombre de contrat en brouillon/validés/signés*/
            let contracts_configs = await this.getContractsConfigByStallionAndSeason(horse_id, season_id, 'light')
            for (let i = 0; i < contracts_configs.length; i++) {
                let contracts = await this.$storage.db.t('contract')
                .then(table => {
                    return table.where({
                        contract_contractconfig: contracts_configs[i].contractconfig_id,
                    })
                })
                .then(item => {
                    return ContractTransformer.process(item, 'withLastAvenantLight')
                })

                for (let j = 0; j < contracts.length; j++) {
                    if(contracts[j].last_avenant) {
                        if(contracts[j].last_avenant.avenant_status == "1")
                        {
                            nb_brouillon ++
                        }

                        if(contracts[j].last_avenant.avenant_status == "2" || contracts[j].last_avenant.avenant_status == "3")
                        {
                            nb_valides_signes++
                        }
                        horses.push(contracts[j].last_avenant.avenant_horse)
                    }
                }
            }

            let season_mare = await this.$storage.db.t('season_mare')
                .then(table => {
                    return table.where({
                        seasonmare_season: season_id
                    }).filter(function(elem){
                        return horses.includes(elem.seasonmare_horse) === false
                    })
                    .primaryKeys()
                })

            let season_mare_stallion = await this.$storage.db.t('season_mare_stallion')
                .then(table => {
                    return table.where('seasonmarestallion_seasonmare').anyOf(season_mare).filter(function(elem){
                        return elem.seasonmarestallion_horse == horse_id
                    }).primaryKeys()
                })

            return {
                "brouillon": nb_brouillon,
                "valides_signes": nb_valides_signes,
                "without_contrats": season_mare_stallion.length,
            }
        },

		getCaContractInvoiceMode: async function() {
			const url = Constants.CONTRACT_CA_PRIVACY_POLICY_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("ContractMixin::getCaContractInvoiceMode", url)
				.then(res => res.retour)
				.catch(e => {
					console.error("ContractMixin::getCaContractInvoiceMode => ERROR", e)
					return null
				})
		},
        saveCaContractInvoiceMode: async function(params) {
			const url = Constants.CONTRACT_CA_PRIVACY_POLICY_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const data = {
				'module': 'contract_invoice_mode',
				'values': params
			}

			return this.$request.request_post_api("ContractMixin::saveCaContractInvoiceMode", url, data, false)
				.then(res => res.retour)
				.catch(e => {
					console.error("ContractMixin::saveCaContractInvoiceMode => ERROR", e)
					return null
				})
		},

		getCaContractPrivacyPolicy: async function() {
			const url = Constants.CONTRACT_CA_PRIVACY_POLICY_URL + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("ContractMixin::getCaContractPrivacyPolicy", url)
				.then(res => res.retour.contract_privacy)
				.catch(e => {
					console.error("ContractMixin::getCaContractPrivacyPolicy => ERROR", e)
					return null
				})
		},

		saveCaContractPrivacyPolicy: async function(params) {
			const url = Constants.CONTRACT_CA_PRIVACY_POLICY_URL+"?licence_key="+Constants.USER_LICENCE_KEY
			const data = {
				'module': 'contract_privacy',
				'values': JSON.stringify(params)
			}

			return this.$request.request_post_api("ContractMixin::getConditionsMonteCaInvoice", url, data, false)
				.then(res => res.retour)
				.catch(e => {
					console.error("ContractMixin::setConditionsMonteCaInvoice => ERROR", e)
					return null
				})
		},

		markSendedContract: async function(contract_ids) {
            contract_ids = this.$sync.replaceWithReplicated('contract_id', contract_ids)

            return this.$storage.db.t('contract')
            .then(table => {
                return table.where('contract_id')
                .anyOf(contract_ids)
                .modify({
                    contract_sent: 1
                })
            })
		},
        loadConditionsGlobal: async function(season_id){
            const url = this.constructRoute(Constants.CONDITIONS_GLOBAL_URL, {season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_get_api("ContractMixin::loadConditionsGlobal", url)
			.catch(error => {
				console.error("ContractMixin::loadConditionsGlobal => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },
        loadConditionsSeasonContractConfig: async function(season_id){
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_GLOBAL_URL, {season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_get_api("ContractMixin::loadConditionsSeasonContractConfig", url)
			.catch(error => {
				console.error("ContractMixin::loadConditionsSeasonContractConfig => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },
        getStallionContractConfigFromGlobal: async function(contractconfigglobal_id, contractconfigglobaltypemonte_id){
            let url = null
            if(contractconfigglobaltypemonte_id) {
                url = this.constructRoute(Constants.CONTRACT_CONFIG_GLOBAL_STALLIONS_WITH_MONTE_URL, {contractconfigglobal_id, contractconfigglobaltypemonte_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            }
            else{
                url = this.constructRoute(Constants.CONTRACT_CONFIG_GLOBAL_STALLIONS_URL, {contractconfigglobal_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            }
			
			const result = await this.$request.request_get_api("ContractMixin::getStallionContractConfigFromGlobal", url)
			.catch(error => {
				console.error("ContractMixin::getStallionContractConfigFromGlobal => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },
        getConditionsFromGlobal: async function(contractconfigglobaltypemonte_id){
            const url = this.constructRoute(Constants.CONTRACT_GLOBAL_STALLIONS_CONDITIONS_URL, {contractconfigglobaltypemonte_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_get_api("ContractMixin::getConditionsFromGlobal", url)
			.catch(error => {
				console.error("ContractMixin::getConditionsFromGlobal => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },
        getStallionTypeMonte: async function(contractconfigglobaltypemonte_id){
            const url = this.constructRoute(Constants.CONTRACT_GLOBAL_STALLIONS_TYPEMONTE_URL, {contractconfigglobaltypemonte_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_get_api("ContractMixin::getConditionsFromGlobal", url)
			.catch(error => {
				console.error("ContractMixin::getConditionsFromGlobal => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },
        loadHorsesNotInContractConfig: async function(contractconfigglobal_id){
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_GLOBAL_NOT_HORSES, {contractconfigglobal_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			const result = await this.$request.request_get_api("ContractMixin::loadHorsesNotInContractConfig", url)
			.catch(error => {
				console.error("ContractMixin::loadHorsesNotInContractConfig => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },
        saveContractConfigGlobal: async function(season_id, form){
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_GLOBAL_URL, {season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("ContractMixin::saveContractConfigGlobal", url, {form}, false)
            .then(res => res.retour)
            .catch(e => {
                console.error("ContractMixin::saveContractConfigGlobal => ERROR", e)
                return null
            })
        },
        saveContractConfigGlobalConditionsStallions: async function(contractconfigglobal_id, form){
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_GLOBAL_STALLIONS_COND_URL, {contractconfigglobal_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("ContractMixin::saveContractConfigGlobal", url, {form}, false)
            .then(res => res.retour)
            .catch(e => {
                console.error("ContractMixin::saveContractConfigGlobal => ERROR", e)
                return null
            })
        },
        saveDistributionConditions: async function(contractconfigtypemonte_ids, contractconfigglobaltypemonte_id){
            const url = this.constructRoute(Constants.CONTRACT_GLOBAL_STALLIONS_CONDITIONS_URL, {contractconfigglobaltypemonte_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("ContractMixin::saveDistributionConditions", url, {contractconfigtypemonte_ids: contractconfigtypemonte_ids.split(',')}, false)
            .then(res => res.retour)
            .catch(e => {
                console.error("ContractMixin::saveDistributionConditions => ERROR", e)
                return null
            })
        },
        saveStallionToConditionsParent: async function(contractconfigglobaltypemonte_id, contractconfig_ids){
            const url = this.constructRoute(Constants.CONTRACT_GLOBAL_STALLIONS_DISTRIBUTE_URL, {contractconfigglobaltypemonte_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("ContractMixin::saveStallionToConditionsParent", url, {contractconfig_ids}, false)
            .then(res => res.retour)
            .catch(e => {
                console.error("ContractMixin::saveStallionToConditionsParent => ERROR", e)
                return null
            })
        },
        saveContractConfigStallionNumberTemplate: async function(elems){
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_NUMBER_TEMPLATE_URL, {}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("ContractMixin::saveContractConfigStallionNumberTemplate", url, {elems}, false)
            .then(res => res.retour)
            .catch(e => {
                console.error("ContractMixin::saveContractConfigStallionNumberTemplate => ERROR", e)
                return null
            })
        },
        createContractConfigFromGlobal: async function(horses, contractconfigglobal_id){
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_CREATE_FROM_GLOBAL, {contractconfigglobal_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			
			return this.$request.request_post_api("ContractMixin::saveContractConfigStallionNumberTemplate", url, {horses}, false)
            .then(res => res.retour)
            .catch(e => {
                console.error("ContractMixin::saveContractConfigStallionNumberTemplate => ERROR", e)
                return null
            })
        },
        deleteContractConfigFromGlobal: async function(contractconfigglobal_id, contractconfig_ids){
            const params = {contractconfig_ids: contractconfig_ids}
            
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_DELETE_FROM_GLOBAL, {contractconfigglobal_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			return this.$request.request_post_api("ContractMixin::saveContractConfigStallionNumberTemplate", url, params, false)
            .then(res => res.retour)
            .catch(e => {
                console.error("ContractMixin::saveContractConfigStallionNumberTemplate => ERROR", e)
                return null
            })
        },
        loadSeasonByIdWithContractConfig: async function(season_id, light = false) {
            return this.$storage.db.t('season')
            .then(table => {
                return table.get(parseInt(season_id))
            })
            .then(col => {
                if(light) 
                    return SeasonTransformer.process(col, 'withContractsConfigStallionLight')
                return SeasonTransformer.process(col, 'withContractsConfigStallion')
            })
        },

        updateContract: async function(contract_ids) {
            await Common.asyncForEach(contract_ids, async (contract_id) => {
                const contract = await this.$storage.db.t('contract')
                .then(table => {
                    return table.where('contract_id')
                    .equals(contract_id)
                })
                .then((col) => {
                    return col.first()
                })

                await this.$storage.db.t('contract')
                .then(table => {
                    return table.where('contract_id')
                    .equals(contract_id)
                    .modify({
                        contract_fake: !contract.contract_fake
                    })
                })
            })
        },

        fastEditContract: function(contract_infos, avenant_infos) {
            return this.$storage.db.transaction(
				'rw',
				['contract', 'contract_avenant'],
				async () => {
                    await this.$storage.db.t('contract')
                    .then(table => {
                        return table.where('contract_id')
                        .equals(parseInt(contract_infos.contract_id))
                        .modify({
                            contract_note: contract_infos.contract_note,
                        })
                    })

                    await this.$storage.db.t('contract_avenant')
                    .then(table => {
                        return table.where('avenant_id')
                        .equals(parseInt(avenant_infos.avenant_id))
                        .modify({
                            avenant_dn: avenant_infos.avenant_dn,
                            avenant_dn_bool: avenant_infos.avenant_dn_bool,
                            avenant_dps: avenant_infos.avenant_dps,
                            avenant_as_bool: avenant_infos.avenant_as_bool,
                            avenant_date_validation: avenant_infos.validation_date,
                            avenant_date_signature: avenant_infos.signature_date,
                            avenant_comment: avenant_infos.avenant_comment
                        })
                    })

                    ContractAvenantCleaner.inst().onMutation([avenant_infos.avenant_id], ['avenant'])
                }
            )
        },

        fastEditContractv2: async function(contract_infos, avenant_infos) {
            let contract_id = contract_infos.contract_id
            const params = {
                contract_infos: contract_infos,
                avenant_infos: avenant_infos
            }

            const url = this.constructRoute(Constants.CONTRACT_FAST_SAVE_URL, { contract_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ModelMixin::fastEditContractv2", url, params, false)
			.catch(error => {
				console.error("ModelMixin::fastEditContractv2 => ERROR", error)
				return null
			})

			if(result) return result.retour
			return null
        },

        async UndoCancelContract(contract_id, infos) {
            let contract_avenant = await this.$storage.db.t('contract_avenant')
            .then(table => {
                return table.where('avenant_type').notEqual(parseInt(7))
                .and(avenant => avenant.avenant_contract == parseInt(contract_id))
            })
            .then(col => {
                return ContractAvenantTransformer.process(col)
            })
            .then(col => {
                return _orderBy(col, ['avenant_num'], ['desc'])[0]
            })

            if(contract_avenant.avenant_type) {

                let new_avenant = {
                    avenant_id: Common.getNegativeId(),
                    avenant_num: (contract_avenant.contract.avenant.avenant_num * 1) + 1,
                    avenant_justification: infos.avenant_justification,
                    avenant_comment: infos.avenant_comment,
                    avenant_contract: contract_id,
                    avenant_cmep: infos.cmep.tiers_id,
                    avenant_type: contract_avenant.avenant_type,
                    avenant_status: 1,
                    avenant_free: infos.avenant_free,
                    avenant_date: new Date(),
                    avenant_horse: contract_avenant.contract.avenant.avenant_horse,
                    avenant_transfert: infos.avenant_transfert ? 1 : 0,

                    avenant_model: infos.modele.model_id,
                    avenant_typemonte: infos.type_monte.id,
                    avenant_valide: 1,

                    avenant_date_signature: null,
                    avenant_date_validation: null,
                    avenant_dps: contract_avenant.contract.avenant.avenant_dps,
                    avenant_type_contrat: contract_avenant.contract.avenant.avenant_type_contrat,
                    avenant_porteuse: infos.avenant_porteuse
                }

                const avenant_id = await this.$storage.db.t('contract_avenant')
                .then(table => {
                    return table.add(new_avenant)
                })

                ContractAvenantCleaner.inst().onMutation([avenant_id], ['avenant'])

                return avenant_id
            }
            else {
                this.failureToast()
            }
        },

        loadExportContract: async function(season_id) {
			const url = Constants.CONTRACT_EXPORT + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("ContractMixin::loadExportContract", url, {season_id: season_id}, false)
			.catch(error => {
				console.error("ContractMixin::loadExportContract => ERROR", error)
				return null
			})

			Common.csvToFile(result['lines'], result['name'] + "?" + result['extension'])
        },

        deleteExportContract: async function() {
        	const url = Constants.CONTRACT_EXPORT + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_delete_api("ContractMixin::deleteExportContract", url, {})
			.catch(e => {
				console.error("ContractMixin::deleteExportContract", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

            this.successToast()
			return response.retour
		},

        forceIndexationContract: async function(contract_ids) {
            ContractCleaner.inst().onMutation(contract_ids, ['fractions', 'avenant', 'paillettes', 'invoices', 'porteurs_parts', 'season_mare_stallion', 'doses'])
        },

        // DOCUMENT
        getDocumentByContract: async function(contract_id) {
            const url = this.constructRoute(Constants.CONTRACT_DOCUMENT_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixins::getDocumentByContract", url)
            if(result) return result.retour
            return null
        },

        getUrlContractDocument(contract_id) {
            return this.constructRoute(Constants.CONTRACT_DOCUMENTS_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
        },

        addContractDocument: async function(contract_id, params) {
            const url = this.constructRoute(Constants.CONTRACT_DOCUMENT_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::getDocumentByContract", url, params, false)
            if(result) return result.retour
            return null
        },

        uploadDocumentContractFile: async function(contract_id, document_id, document_file) {
            const url = this.constructRoute(Constants.CONTRACT_DOCUMENT_FILE_URL, {contract_id, document_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

            return this.$request.request_post_file_api("ContractMixins::uploadDocumentContractFile", url, [
                { 
                    name: 'document',
                    content: document_file
                }])
                .catch(e => {
                    console.error("ContractMixins::uploadDocumentContractFile => ERROR", e)
                    return null
                })
                .then(res => {
                    return res.retour
                })
        },

        editContractDocument: async function(contract_id, document_id, params) {
            const url = this.constructRoute(Constants.CONTRACT_DOCUMENT_BY_ID_URL, {contract_id, document_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::editContractDocument", url, params, false)
            if(result) return result.retour
            return null
        },
        createAvenantArticlesFromInvoiceDetails: async function(contract_id, details) {
            const url = this.constructRoute(Constants.CONTRACT_INVOICE_DETAILS_AVENANT_ARTICLES_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::createAvenantArticlesFromInvoiceDetails", url, {details}, false)
            if(result) return result.retour
            return null
        },
        delierInvoiceDetailsAvenantArticles: async function(contract_id, avenantarticles_id) {
            const url = this.constructRoute(Constants.CONTRACT_DELIER_INVOICE_DETAILS_AVENANT_ARTICLES_URL, {contract_id, avenantarticles_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixins::delierInvoiceDetailsAvenantArticles", url, {}, false)
            .catch(e => {
				console.error("ContractMixin::delierInvoiceDetailsAvenantArticles", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

            this.successToast()
            if(result) return result.retour
            return null
        },

        deleteContractDocument: async function(contract_id, document_id) {
            const url = this.constructRoute(Constants.CONTRACT_DOCUMENT_BY_ID_URL, {contract_id, document_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_delete_api("ContractMixins::deleteContractDocument", url)
            if(result) return result.retour
            return null
        },

        saveContractVersion: async function(contract_id) {
            const url = this.constructRoute(Constants.CONTRACT_SAVE_VERSION_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixins::saveContractVersion", url)
            if(result) return result.retour
            return null
        },

        removeContractConfig: async function(contractconfig_id) {
            const contract = await this.$storage.db.t('contract')
                .then(table => {
                    return table.where({
                        contract_contractconfig: parseInt(contractconfig_id)
                    })
                    .first()
                })

            if(!contract) {
                await this.$storage.db.t('contract_config')
                .then(table => {
                    return table.where({
                        contractconfig_id: parseInt(contractconfig_id),
                    })
                    .invalid()
                })
            }
            else {
                this.failureToast('toast.has_contracts')
            }
        },

        printBlankContract: async function(contractconfig_id, model_id, condition_id, lang) {
            const params = {contractconfig_id, model_id, condition_id, lang}
            const url = this.constructRoute(Constants.CONTRACT_BLANK_PDF_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("ContractMixin::printBlankContract", url, {}, false)
			.catch(error => {
				console.error("ContractMixin::printBlankContract => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			if(result) Common.base64ToPdf(result, "contract_blank_" + contractconfig_id + ".pdf")
            return null
        },

        getCountContractsStatusByStallion: async function(stallion_id, season_id) {
            const url = this.constructRoute(Constants.CONTRACT_GET_CONTRACTS_STATUS_STALLION, {stallion_id, season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getCountContractsStatusByStallion", url)

            if(result) return result.retour
            return null
        },

        sendMailDocumentContract: async function(contract_id, documents, model_id = null, commentaire = null, expeditor = null, courtier_mail = null, mails_coproprios = null, insemination_center_mail= null, send_mail_sender = false ) {
            const url = Constants.CONTRACT_DOCUMENT_SEND_URL + "?licence_key="+Constants.USER_LICENCE_KEY

            const params = {
                contract_id,
                documents,
                model_id,
                expeditor,
                commentaire,
                courtier_mail,
                mails_coproprios,
                insemination_center_mail,
                send_mail_sender,
            }

            const result = await this.$request.request_post_api("ContractMixin::sendMailDocumentContract", url, params, false)
            .catch(error => {
                console.error("ContractMixin::sendMailDocumentContract => ERROR", error)
                return null
            })

            return result ? result.retour : null
        },

        getContractConfigInfos: async function(contractconfig_id) {
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_URL, {contractconfig_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractConfigInfos", url)

            if(result) return result.retour
            return null
        },

        createContractLot: async function(contracts) {
            const url = Constants.CONTRACT_CREATE_LOT + "?licence_key="+Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("ContractMixin::createContractLot", url, {contracts}, false)
                .then(res => res.retour)
                .catch(e => {
                    console.error("ContractMixin::createContractLot => ERROR", e)
                    return null
                })
        },

        printRelanceCentre: async function(tiers_id, model_id, contracts_ids, type) {
            const params = {contracts_ids, type}
            const url = this.constructRoute(Constants.CONTRACT_RELANCE_CENTRE, {tiers_id, model_id}) + "?licence_key=" + Constants.USER_LICENCE_KEY
			const result = await this.$request.request_post_api("ContractMixin::printRelanceCentre", url, params, false)
			.catch(error => {
				console.error("ContractMixin::printRelanceCentre => ERROR", error)
				this.failureToast("toast.info_save_failed")
				return null
			})

			if(result) Common.base64ToPdf(result, this.getTrad('monte.relance_centre') + ".pdf")
            return null
        },

        getContractBySeasonMareStallion: async function(seasonmarestallions) {
            const url = this.constructRoute(Constants.CONTRACT_COUPLE_URL, {seasonmarestallion_ids: seasonmarestallions.join(',')}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getContractBySeasonMareStallion", url)

            if(result) return result.retour
            return null
        },

        deleteContractAvenantsArticles: async function(avenantarticles_ids, contract_ids) {
            const url = Constants.CONTRACT_AVENANT_ARTICLES_DELETE + "?licence_key="+Constants.USER_LICENCE_KEY

            return this.$request.request_post_api("ContractMixin::deleteContractAvenantsArticles", url, {avenantarticles_ids, contract_ids}, false)
                .then(res => res.retour)
                .catch(e => {
                    console.error("ContractMixin::deleteContractAvenantsArticles => ERROR", e)
                    return null
                })
        },

        getStallionSeasonContract: async function(stallion_id, season_id) {
            const url = this.constructRoute(Constants.CONTRACTS_STALLION_SEASON_URL, {stallion_id, season_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ContractMixin::getStallionSeasonContract", url)

            if(result) return result.retour
            return null
        },

        deleteConditionsContractConfigGlobal: async function(contractconfigglobaltypemonte_id) {
            const url = this.constructRoute(Constants.CONTRACT_CONFIG_GLOBAL_URL, {season_id: contractconfigglobaltypemonte_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

            const response = await this.$request.request_delete_api("ContractMixin::deleteConditionsContractConfigGlobal", url, {})
            .catch(e => {
                console.error("ContractMixin::deleteConditionsContractConfigGlobal", e)
                return null
            })

            return response.retour
        },

        generateContractSignatureLink: async function(contract_id, params) {
            const url = this.constructRoute(Constants.CONTRACT_SEND_SIGNATURE_URL, {contract_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ContractMixin::generateContractSignatureLink", url, params, false)
            if(result) return result.retour
            return null
        }

	}
}
export default ContractMixin
